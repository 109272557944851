import React, { useEffect, useState } from "react";
import api from "../functions/api";
import Page from "../components/layout/Page";
import AbstimmungBlock from "../components/abstimmung/AbstimmungBlock";

const Abstimmungen = () => {
	const [polls, setPolls] = useState([]);

	async function getPolls() {
		api.getPollsByGroup(2).then((res) => {
			setPolls(res);
		});
	}

	useEffect(() => {
		getPolls();
	}, []);

	return (
		<Page title="Abstimmungen">
			<p>Coming soon!</p>
		</Page>
	);
};

export default Abstimmungen;
