import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/logo.svg";
import UserContext from "../context/UserContext";
import api from "../functions/api";

// Tutorial: https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [fehler, setFehler] = useState(false);

	const { setUser } = useContext(UserContext);

	function login(email, password) {
		api.postLogin(email, password)
			.then((res) => {
				if (res.success) {
					setUser(res.user);
				} else {
					setFehler(true);
				}
			})
			.catch(() => setFehler(true));
	}

	return (
		<div className="beyond">
			<div className="beyond__box">
				<div className="beyond__box__logo">
					<img
						src={logo}
						alt="Logo des Abi-Planers"
						className="sidebar__logo-link__img"
						height="48px"
						width="48px"
					/>
					<h1 className="sidebar__logo-link__h">abiPlaner</h1>
				</div>

				<h2>Login</h2>
				<Link to="/reset">Passwort vergessen?</Link>

				{
					// Fehlermeldung bei Fehler
					fehler ? (
						<div
							className="beyond__box__fehler beyond__box__fehler--zeigen"
							aria-hidden="false"
						>
							Es ist ein Fehler bei der Anmeldung aufgetreten.
						</div>
					) : (
						<div
							className="beyond__box__fehler"
							aria-hidden="true"
						></div>
					)
				}

				<form
					onSubmit={(e) => {
						e.preventDefault();
						login(email, password);
					}}
				>
					<input
						type="text"
						name="mail"
						id="mail"
						placeholder="E-Mail"
						autoComplete="current-email"
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>

					<input
						type="password"
						name="password"
						id="password"
						placeholder="Passwort"
						autoComplete="current-password"
						onChange={(e) => {
							setPassword(e.target.value);
						}}
					/>

					<button className="beyond__box__button">Login</button>
				</form>
			</div>
			<footer>
				<a href="/datenschutz">Datenschutzerklärung</a>
			</footer>
		</div>
	);
};

export default Login;
