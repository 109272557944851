import axios from "axios";
import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import Popup from "../layout/Popup";

const EditFinanzierungPopup = ({ close, finanz }) => {
	const token = useContext(UserContext).user.token;

	const [name, setName] = useState(finanz.name);
	const [betrag, setBetrag] = useState(finanz.betrag);

	function editFinanz() {
		axios
			.post(getAPI() + "actions/edit.php", {
				id: token,
				action: "finanzierung",
				fid: finanz.finanzierung_id,
				name: name,
				betrag: betrag,
			})
			.then(() => {
				close();
			});
	}

	function deleteFinanz() {
		axios
			.delete(getAPI() + "actions/delete.php", {
				data: {
					id: token,
					action: "finanzierung",
					fid: finanz.finanzierung_id,
				},
			})
			.then(() => {
				close();
			});
	}

	return (
		<Popup close={() => close()} className="einstellungen__benutzer__popup">
			<h3>{finanz.name}</h3>

			<form
				onSubmit={(e) => {
					e.preventDefault();
					editFinanz();
				}}>
				<input
					type="text"
					name="name"
					id="name"
					placeholder="Name"
					value={name}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>

				<input
					type="number"
					name="betrag"
					id="betrag"
					placeholder="Betrag"
					value={betrag}
					onChange={(e) => {
						setBetrag(e.target.value);
					}}
					max="100000"
					min="1"
				/>

				<div className="buttons">
					<button
						type="button"
						onClick={(e) => {
							e.preventDefault();
							deleteFinanz();
						}}
						tabIndex={1}>
						Löschen
					</button>
					<button type="submit" tabIndex={0}>
						Speichern
					</button>
				</div>
			</form>
		</Popup>
	);
};

export default EditFinanzierungPopup;
