import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Cards = ({ cards }) => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		var i = 0;
		cards.forEach((card) => {
			card.id = i;
			i++;
		});

		setLoading(false);
	}, [cards]);

	if (!loading && cards.length > 0) {
		return (
			<ul className="cards">
				{cards[0].link === undefined
					? cards.map((card) => {
							return (
								<li className="card" key={card.id}>
									<h4>{card.title}</h4>
									<p>{card.body}</p>
								</li>
							);
					  })
					: cards.map((card) => {
							return (
								<li className="card" key={card.id}>
									<Link to={card.link}>
										<h4>{card.title}</h4>
										<p>{card.body}</p>
									</Link>
								</li>
							);
					  })}
			</ul>
		);
	}
};

export default Cards;
