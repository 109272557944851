import React, { useState } from "react";
import { getAPI } from "../../data/config";

const ZeitungSteckbriefBlock = ({ user, profile, categories }) => {
	const [ausgeklappt, setAusgeklappt] = useState(false);

	return (
		<li className="abstimmung-block">
			<div
				className={
					"abstimmung-block__oben " +
					(ausgeklappt ? "abstimmung-block__oben--aktiv" : "")
				}>
				<div
					className="abstimmung-block__oben__links"
					onClick={() => {
						setAusgeklappt((c) => !c);
					}}>
					<svg viewBox="0 0 24 16" className="arrow">
						<g transform="matrix(3.34134,0,0,3.34134,-28.1049,-31.1062)">
							<path d="M8.71,12.29L11.3,9.7C11.69,9.31 12.32,9.31 12.71,9.7L15.3,12.29C15.93,12.92 15.48,14 14.59,14L9.41,14C8.52,14 8.08,12.92 8.71,12.29Z" />
						</g>
					</svg>

					<img
						src={getAPI() + "public/images/" + user.picture}
						alt="Profilbild"
						width="32px"
						height="32px"
						className="round"
					/>
					{user.firstname + " " + user.lastname}
				</div>

				<div className="abstimmung-block__oben__rechts">
					<p className="abstimmung-block__oben__rechts__votes">
						{profile.length + " / " + categories.length}
					</p>
				</div>
			</div>
			{ausgeklappt && (
				<div className="abstimmung-block__inhalt">
					<ul className="zeitung__steckbrief__other-profile">
						{categories.map((c) => (
							<li key={c.categoryID}>
								<p className="zeitung__steckbrief__other-profile__category">
									{c.category}:
								</p>
								<p>
									{
										profile.find(
											(p) => p.categoryID === c.categoryID
										)?.answer
									}
								</p>
							</li>
						))}
					</ul>
				</div>
			)}
		</li>
	);
};

export default ZeitungSteckbriefBlock;
