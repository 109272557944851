import axios from "axios";
import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import Popup from "../layout/Popup";

const EditUserPopup = ({ close, user }) => {
	const [userRolle, setUserRolle] = useState(user.role);

	const userc = useContext(UserContext).user;
	const token = userc.token;

	function getRollenname(rollenid) {
		switch (rollenid) {
			case 1:
				return "Nutzer";
			case 2:
				return "Verwalter";
			case 3:
				return "Admin";
			default:
				return "Sonstiges";
		}
	}

	function editUser() {
		axios
			.post(getAPI() + "actions/edit.php", {
				id: token,
				action: "user_rolle",
				rolle: userRolle,
				user: user.user_id,
			})
			.then(() => {
				close();
			});
	}

	function deleteUser() {
		axios
			.delete(getAPI() + "actions/delete.php", {
				data: {
					id: token,
					action: "user",
					userid: user.user_id,
				},
			})
			.then(() => {
				close();
			});
	}

	return (
		<Popup close={() => close()} className="einstellungen__benutzer__popup">
			<img
				src={getAPI() + "public/images/" + user.profilbild}
				alt={"Profilbild von " + user.vorname}
			/>
			<h3>{user.vorname + " " + user.nachname}</h3>
			<p>{user.email}</p>
			<select
				name="rolle"
				id="rolle"
				onChange={(e) => setUserRolle(e.target.value)}>
				<option value={1} selected={userRolle === 1}>
					{getRollenname(1)}
				</option>
				<option value={2} selected={userRolle === 2}>
					{getRollenname(2)}
				</option>
				<option value={3} selected={userRolle === 3}>
					{getRollenname(3)}
				</option>
			</select>
			<div className="buttons">
				<button
					onClick={(e) => {
						e.preventDefault();
						deleteUser();
					}}>
					Löschen
				</button>
				<button
					onClick={(e) => {
						e.preventDefault();
						editUser();
					}}>
					Speichern
				</button>
			</div>
		</Popup>
	);
};

export default EditUserPopup;
