import React, { useEffect, useState } from "react";
import api from "../../functions/api";
import { getAPI } from "../../data/config";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import NotificationsOffRoundedIcon from "@mui/icons-material/NotificationsOffRounded";
import Checkbox from "../../components/Checkbox";

// TUTORIALS
// https://medium.com/@seladir/how-to-implement-web-push-notifications-in-your-node-react-app-9bed79b53f34

const EinstellungenBenachrichtigungen = () => {
	return <p>Coming soon</p>;

	const vapid =
		"BCAhxfg9zmwSGHxzZlqYWnh-lp0KLmltVqjQnelyZmLGQlAyKKTncAMiUYLV-m-De1n2OIwMKvdWjiT0Ojiq_fI";

	const convertedVapidKey = urlBase64ToUint8Array(vapid);

	function urlBase64ToUint8Array(base64String) {
		const padding = "=".repeat((4 - (base64String.length % 4)) % 4);

		const base64 = (base64String + padding)
			//eslint-disable-next-line
			.replace(/\-/g, "+")
			.replace(/_/g, "/");

		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	}

	function sendSubscription(subscription) {
		return fetch(`${getAPI()}notifications/subscribe`, {
			method: "POST",
			body: JSON.stringify(subscription),
			headers: {
				"Content-Type": "application/json",
			},
		});
	}

	async function subscribeUser() {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.ready
				.then(function (registration) {
					if (!registration.pushManager) {
						console.log("Push manager unavailable.");
					}

					registration.pushManager
						.getSubscription()
						.then(function (existedSubscription) {
							if (existedSubscription === null) {
								console.log(
									"No subscription detected, make a request."
								);

								// ask for notification permission
								Notification.requestPermission().then(
									(permission) => {
										if (permission === "granted") {
											console.log(
												"Notification permission granted."
											);
										} else {
											console.log(
												"Unable to get permission to notify."
											);
										}
									}
								);

								registration.pushManager
									.subscribe({
										applicationServerKey: convertedVapidKey,
										userVisibleOnly: true,
									})
									.then(function (newSubscription) {
										console.log("New subscription added.");
										sendSubscription(newSubscription);
									})
									.catch(function (e) {
										if (
											Notification.permission !==
											"granted"
										) {
											console.log(
												"Permission was not granted."
											);
										} else {
											console.error(
												"An error ocurred during the subscription process.",
												e
											);
										}
									});
							} else {
								console.log("Existed subscription detected.");
								sendSubscription(existedSubscription);
							}
						});
				})
				.catch(function (e) {
					console.error(
						"An error ocurred during Service Worker registration.",
						e
					);
				});
		}
	}

	const [subscribed, setSubscribed] = useState(false);
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState([]);
	const [subscription, setSubscription] = useState(null);

	useEffect(() => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.ready
				.then(async function (registration) {
					registration.pushManager
						.getSubscription()
						.then(async function (subscription) {
							if (subscription !== null) {
								setSubscription(subscription);
								api.getNotificationCategories(
									subscription
								).then((res) => {
									setCategories(res);
								});
								setSubscribed(true);
							}
							setLoading(false);
						});
				})
				.catch(function (e) {
					console.error(
						"An error ocurred during Service Worker registration.",
						e
					);
				});
		} else {
			setLoading(false);
		}
	}, []);

	if (!("Notification" in window)) {
		return (
			<div className="zeitung__description">
				<p>
					Leider sind Benachrichtigungen auf deinem Gerät zur Zeit
					nicht verfügbar.
				</p>
			</div>
		);
	}

	if (loading) {
		return;
	}

	return (
		<div className="settings__notifications">
			{!subscribed ? (
				<button
					className="settings__notifications__button settings__notifications__button--activate"
					onClick={async (e) => {
						e.preventDefault();
						await subscribeUser();
						if (Notification.permission === "granted") {
							setSubscribed(true);
						}
					}}
				>
					<NotificationsRoundedIcon />
					Benachrichtigungen aktivieren
				</button>
			) : (
				<div>
					<button
						className="settings__notifications__button settings__notifications__button--deactivate"
						onClick={(e) => {
							e.preventDefault();
							if (
								window.confirm(
									"Wenn du Benachrichtigungen deaktivierst, erhältst du zukünftig keine Benachrichtigungen mehr auf dieses Gerät und alle bisher vorgenommenen Einstellungen werden gelöscht."
								)
							) {
								console.log("Deactivate notifications");
							}
						}}
					>
						<NotificationsOffRoundedIcon />
						Benachrichtigungen deaktivieren
					</button>
					<b className="settings__notifications__info">
						Du erhältst auf diesem Gerät Benachrichtigungen.
					</b>
					<section>
						<h3>Kategorien</h3>
						<ul className="settings__notifications__categories list">
							{categories.map((category) => {
								return (
									<li key={category.categoryID}>
										<label>{category.category}</label>
										<Checkbox
											id={category.categoryID}
											checked={category.setting}
											onChange={(state) => {
												api.postNotificationPreference(
													subscription,
													category.categoryID,
													state
												);
											}}
										/>
									</li>
								);
							})}
						</ul>
					</section>
				</div>
			)}
		</div>
	);
};

export default EinstellungenBenachrichtigungen;
