import React, { useContext, useState } from "react";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import Textfeld from "../Textfeld";
import axios from "axios";
import { getAPI } from "../../data/config";
import UserContext from "../../context/UserContext";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MoreMenu from "../layout/MoreMenu";
import api from "../../functions/api";
import { useEffect } from "react";

const AbstimmungEintrag = ({ eintrag, getAbstimmung, beendet }) => {
	const user = useContext(UserContext).user;

	const [ausgeklappt, setAusgeklappt] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editValue, setEditValue] = useState(eintrag.vorschlag);

	const [votes, setVotes] = useState(Number(eintrag.votes));
	const [yourVote, setYourVote] = useState(eintrag.yourVote);

	const [comments, setComments] = useState(eintrag.comments);

	function postComment(comment) {
		api.postComment(eintrag.nominationID, comment).then(() => {
			setComments((comments) => [
				...comments,
				{
					commentID: 1000000,
					comment: comment,
					user: user,
				},
			]);
		});
	}

	function deleteNomination() {
		api.deleteNomination(eintrag.nominationID).then(() => {
			getAbstimmung();
		});
	}

	function editVorschlag() {
		axios
			.post(getAPI() + "actions/edit.php", {
				id: user.token,
				action: "vorschlag",
				vorschlag: eintrag.vorschlag_id,
				text: editValue,
			})
			.then(() => {
				setEditMode(false);
				getAbstimmung();
			});
	}

	useEffect(() => {
		setVotes(Number(eintrag.votes));
		setYourVote(eintrag.yourVote);
	}, [eintrag]);

	return (
		<li className="poll__nomination">
			<div className="poll__nomination__content">
				<div className="poll__nomination__content__votes">
					<button
						className={
							yourVote === 1
								? "arrow arrow--up arrow--active"
								: "arrow arrow--up"
						}
						disabled={beendet}
						title={'"' + eintrag.text + '" upvoten'}
						onClick={(e) => {
							e.preventDefault();
							api.voteNomination(
								eintrag.nominationID,
								yourVote === 1 ? 0 : 1
							);
							if (yourVote === -1) {
								setVotes(votes + 2);
								setYourVote(1);
							} else if (yourVote === 1) {
								setVotes(votes - 1);
								setYourVote(0);
							} else {
								setVotes(votes + 1);
								setYourVote(1);
							}
						}}
					>
						<svg viewBox="0 0 24 16">
							<g transform="matrix(3.34134,0,0,3.34134,-28.1049,-31.1062)">
								<path d="M8.71,12.29L11.3,9.7C11.69,9.31 12.32,9.31 12.71,9.7L15.3,12.29C15.93,12.92 15.48,14 14.59,14L9.41,14C8.52,14 8.08,12.92 8.71,12.29Z" />
							</g>
						</svg>
					</button>

					<span>{votes}</span>

					<button
						className={
							yourVote === -1
								? "arrow arrow--down arrow--active"
								: "arrow arrow--down"
						}
						disabled={beendet}
						title={'"' + eintrag.text + '" downvoten'}
						onClick={(e) => {
							e.preventDefault();
							api.voteNomination(
								eintrag.nominationID,
								eintrag.yourVote === -1 ? 0 : -1
							);
							if (yourVote === -1) {
								setVotes(votes + 1);
								setYourVote(0);
							} else if (yourVote === 1) {
								setVotes(votes - 2);
								setYourVote(-1);
							} else {
								setVotes(votes - 1);
								setYourVote(-1);
							}
						}}
					>
						<svg viewBox="0 0 24 16">
							<g transform="matrix(3.34134,0,0,3.34134,-28.1049,-31.1062)">
								<path d="M8.71,12.29L11.3,9.7C11.69,9.31 12.32,9.31 12.71,9.7L15.3,12.29C15.93,12.92 15.48,14 14.59,14L9.41,14C8.52,14 8.08,12.92 8.71,12.29Z" />
							</g>
						</svg>
					</button>
				</div>

				{!editMode ? (
					<p>{eintrag.text}</p>
				) : (
					<input
						className="abstimmung__eintrag__edit__feld"
						value={editValue}
						onChange={(e) => setEditValue(e.target.value)}
						onSubmit={() => {
							editVorschlag();
						}}
					/>
				)}

				<div className="list__end">
					<button
						className="poll__nomination__content__comments_button"
						onClick={() => {
							setAusgeklappt((c) => !c);
						}}
					>
						{eintrag.comments && (
							<span>{eintrag.comments.length}</span>
						)}
						<CommentRoundedIcon />
					</button>

					{user.role >= 2 &&
						(editMode ? (
							<button
								onClick={() => {
									editVorschlag();
								}}
							>
								<CheckCircleRoundedIcon className="abstimmung__eintrag__edit" />
							</button>
						) : (
							<MoreMenu
								items={[
									/* { title: "Bearbeiten" }, */
									{
										title: "Löschen",
										action: () => {
											deleteNomination();
										},
									},
									/* { title: "Als Gewinner markieren" }, */
								]}
							/>
						))}
				</div>
			</div>

			{ausgeklappt ? (
				<div className="poll__nomination__comments">
					{comments &&
						comments.map((comment) => {
							return (
								<div
									key={comment.commentID}
									className="poll__nomination__comments__comment"
								>
									<img
										src={
											getAPI() +
											"public/images/" +
											comment.user.picture
										}
										className="round"
										alt="Profilbild"
										width="24px"
										height="24px"
									/>

									<div>
										<p className="poll__nomination__comments__comment__author">
											{comment.user.firstname +
												" " +
												comment.user.lastname}
										</p>
										<p className="poll__nomination__comments__comment__text">
											{comment.comment}
										</p>
									</div>
								</div>
							);
						})}

					<Textfeld
						placeholder="Neuer Kommentar"
						onSubmit={(text) => postComment(text)}
					/>
				</div>
			) : (
				<></>
			)}
		</li>
	);
};

export default AbstimmungEintrag;
