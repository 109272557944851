import React from "react";
import Abstimmung from "../components/abstimmung/Abstimmung";
import Page from "../components/layout/Page";

const Motto = () => {
	return (
		<Page title="Motto">
			<div className="motto">
				<Abstimmung role={1} />
			</div>
		</Page>
	);
};

export default Motto;
