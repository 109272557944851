import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import Abstimmung from "./Abstimmung";
import MoreMenu from "../layout/MoreMenu";
import api from "../../functions/api";

const AbstimmungBlock = ({ abstimmung, getAbstimmungen }) => {
	const user = useContext(UserContext).user;
	const [ausgeklappt, setAusgeklappt] = useState(false);

	return (
		<li className="abstimmung-block">
			<div
				className={
					"abstimmung-block__oben " +
					(ausgeklappt ? "abstimmung-block__oben--aktiv" : "")
				}>
				<div
					className="abstimmung-block__oben__links"
					onClick={() => {
						setAusgeklappt((c) => !c);
					}}>
					<svg viewBox="0 0 24 16" className="arrow">
						<g transform="matrix(3.34134,0,0,3.34134,-28.1049,-31.1062)">
							<path d="M8.71,12.29L11.3,9.7C11.69,9.31 12.32,9.31 12.71,9.7L15.3,12.29C15.93,12.92 15.48,14 14.59,14L9.41,14C8.52,14 8.08,12.92 8.71,12.29Z" />
						</g>
					</svg>
					{abstimmung.title}
				</div>

				<div className="abstimmung-block__oben__rechts">
					<p className="abstimmung-block__oben__rechts__votes">
						{abstimmung.nominations.length}
						<span className="desktop-only">
							{abstimmung.nominations.length === 1
								? " Vorschlag"
								: " Vorschläge"}
						</span>
					</p>
					{user.role >= 2 && (
						<MoreMenu
							items={[
								{
									title: "Löschen",
									action: () => {
										if (
											window.confirm(
												"Möchtest du die Abstimmung " +
													abstimmung.title +
													" wirklich löschen?"
											)
										)
											api.deletePoll(
												abstimmung.pollID
											).then(() => {
												getAbstimmungen();
											});
									},
								},
							]}
						/>
					)}
				</div>
			</div>
			{ausgeklappt && (
				<div className="abstimmung-block__inhalt">
					<Abstimmung
						data={abstimmung}
						abstimmung={abstimmung.pollID}
						getAbstimmungen={getAbstimmungen}
					/>
				</div>
			)}
		</li>
	);
};

export default AbstimmungBlock;
