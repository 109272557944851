import React from "react";
import logo from "../../assets/logo.svg";
import changelog from "../../data/changelog.json";

const EinstellungenUeber = () => {
	return (
		<>
			<section className="settings__about">
				<div className="settings__about__left">
					<img
						src={logo}
						alt="Logo des Abi-Planers"
						className="settings__profile__picture round"
						height="128px"
						width="128px"
					/>
				</div>

				<div className="settings__profile__right">
					<h3>abiPlaner</h3>
					<p>
						Version <b>{changelog[0].version}</b> |{" "}
						{new Date(changelog[0].date).toLocaleDateString(
							"de-DE",
							{
								day: "2-digit",
								month: "2-digit",
								year: "numeric",
							}
						)}
					</p>
					<p>Entwickelt von Constantin Birken, 2022</p>
				</div>
			</section>
			<div className="settings__about__changelog">
				<h2>Changelog</h2>
				<ul>
					{changelog.map((entry, index) => (
						<li
							className="settings__about__changelog__entry"
							key={index}
						>
							<div>
								<h3>{entry.version}</h3>
								<p>
									{new Date(entry.date).toLocaleDateString(
										"de-DE",
										{
											day: "2-digit",
											month: "2-digit",
											year: "numeric",
										}
									)}
								</p>
							</div>
							<ul>
								{entry.changes.map((change, index) => (
									<li key={index}>{change}</li>
								))}
							</ul>
						</li>
					))}
				</ul>
			</div>
		</>
	);
};

export default EinstellungenUeber;
