import React, { useContext, useEffect, useState } from "react";
import api from "../../functions/api";
import UserContext from "../../context/UserContext";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Textfeld from "../../components/Textfeld";

const ZeitungIHNN = () => {
	const user = useContext(UserContext).user;

	const [suggestedQuestions, setSuggestedQuestions] = useState([]);
	const [unansweredQuestions, setUnansweredQuestions] = useState([]);
	const [answeredQuestions, setAnsweredQuestions] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(0);

	async function getQuestions() {
		api.getNhie().then((res) => {
			setSuggestedQuestions(res.filter((q) => q.confirmed === 0));
			setUnansweredQuestions(
				res.filter((q) => q.answer === null && q.confirmed === 1)
			);
			setAnsweredQuestions(
				res.filter((q) => q.answer !== null && q.confirmed === 1)
			);
		});
	}

	useEffect(() => {
		getQuestions();
	}, []);

	function setAnswer(questionID, answer) {
		api.setNhie(questionID, answer).then((res) => {
			setAnsweredQuestions(
				answeredQuestions.concat({
					...unansweredQuestions[currentQuestion],
					answer,
					totalCount:
						unansweredQuestions[currentQuestion].totalCount + 1,
					yesCount:
						unansweredQuestions[currentQuestion].yesCount + answer,
				})
			);
			setUnansweredQuestions(
				unansweredQuestions.filter((q) => q.questionID !== questionID)
			);
			if (currentQuestion === unansweredQuestions.length - 1) {
				setCurrentQuestion(0);
			}
		});
	}

	function unsetAnswer(questionID) {
		api.setNhie(questionID, null).then((res) => {
			const selectedQuestion = answeredQuestions.find(
				(q) => q.questionID === questionID
			);

			setUnansweredQuestions(
				unansweredQuestions.concat({
					questionID: selectedQuestion.questionID,
					question: selectedQuestion.question,
					category: selectedQuestion.category,
					totalCount: selectedQuestion.totalCount - 1,
					yesCount:
						selectedQuestion.yesCount - selectedQuestion.answer,
					answer: null,
				})
			);
			setAnsweredQuestions(
				answeredQuestions.filter((q) => q.questionID !== questionID)
			);
		});
	}

	function nextQuestion() {
		if (currentQuestion < unansweredQuestions.length - 1) {
			setCurrentQuestion(currentQuestion + 1);
		} else {
			setCurrentQuestion(0);
		}
	}

	return (
		<div className="zeitung__ihnn">
			<div className="zeitung__description">
				<p>
					Willkommen bei Ich hab noch nie! Wahrscheinlich kennst du
					das Spiel: Dir werden einige Fragen gestellt, die du mit
					"Hab ich schon mal gemacht" oder "Hab ich noch nie gemacht"
					beantworten kannst.
				</p>
				<p>
					In der Abi-Zeitung werden dann anonym die Ergebnisse
					abgedruckt: Wie viel Prozent der Schüler*innen hatten schon
					ihr erstes Mal, wie viele haben schon mal gekifft?
				</p>
			</div>
			{unansweredQuestions.length > 0 ? (
				<div className="zeitung__ihnn__question">
					<p className="zeitung__ihnn__question__progress">
						{answeredQuestions.length} /{" "}
						{unansweredQuestions.length + answeredQuestions.length}{" "}
						beantwortet
					</p>
					<p className="zeitung__ihnn__question__category">
						{unansweredQuestions.length > 0
							? unansweredQuestions[currentQuestion].category
							: ""}
					</p>
					<p className="zeitung__ihnn__question__question">
						{unansweredQuestions.length > 0
							? unansweredQuestions[currentQuestion].question
							: ""}
					</p>
					<div className="zeitung__ihnn__question__buttons">
						<button
							className="zeitung__ihnn__question__buttons__button--no"
							onClick={(e) => {
								e.preventDefault();
								setAnswer(
									unansweredQuestions[currentQuestion]
										.questionID,
									0
								);
							}}>
							Hab ich noch nie gemacht
						</button>
						<button
							className="zeitung__ihnn__question__buttons__button--yes"
							onClick={(e) => {
								e.preventDefault();
								setAnswer(
									unansweredQuestions[currentQuestion]
										.questionID,
									1
								);
							}}>
							Hab ich schon mal gemacht
						</button>
					</div>
					<button
						className="zeitung__ihnn__question__skip"
						onClick={(e) => {
							e.preventDefault();
							nextQuestion();
						}}>
						Diese Frage vorerst überspringen
					</button>
				</div>
			) : (
				<div className="zeitung__ihnn__question">
					<p className="zeitung__ihnn__question__progress">
						Großartig! Du hast bereits alle Fragen beantwortet.
					</p>
				</div>
			)}

			<div className="zeitung__ihnn__new">
				<h3>Vorschläge für weitere Fragen</h3>
				{suggestedQuestions.length === 0 ? (
					<i>
						Aktuell sind keine Vorschläge für weitere Fragen
						vorhanden. Wenn du denkst, dass eine Frage fehlt, füg
						sie einfach hinzu!
					</i>
				) : (
					<ul className="list">
						{suggestedQuestions.map((q) => (
							<li key={q.questionID}>
								{q.question}
								{user.role >= 2 && (
									<div className="list__end">
										<button
											title="Akzeptieren"
											className="more_menu__button settings__users__waiting__accept"
											onClick={(e) => {
												e.preventDefault();
												api.acceptNhieQuestion(
													q.questionID
												).then(() => {
													getQuestions();
												});
											}}>
											<CheckCircleRoundedIcon />
										</button>
										<button
											className="more_menu__button settings__users__waiting__delete"
											title="Ablehnen"
											onClick={(e) => {
												e.preventDefault();
												api.deleteNhieQuestion(
													q.questionID
												).then(() => {
													getQuestions();
												});
											}}>
											<DeleteRoundedIcon />
										</button>
									</div>
								)}
							</li>
						))}
					</ul>
				)}
				<Textfeld
					placeholder="Neue Frage"
					onSubmit={(text) => {
						if (text.length > 0) {
							api.postNhieQuestion(text).then((res) => {
								getQuestions();
							});
						}
					}}
				/>
			</div>

			{answeredQuestions.length > 0 && (
				<div className="zeitung__ihnn__answered">
					<h3>Bereits beantwortete Fragen</h3>
					{[...new Set(answeredQuestions.map((q) => q.category))].map(
						(c) => (
							<div
								className="zeitung__ihnn__answered__category"
								key={c}>
								<h4>{c}</h4>
								<ul className="list">
									{answeredQuestions
										.filter((q) => q.category === c)
										.map((q) => (
											<li
												key={q.questionID}
												className="zeitung__ihnn__answered__category__list">
												<div>
													{user.role >= 2 && (
														<div
															className="small-progress"
															title={
																Math.round(
																	(1 -
																		q.yesCount /
																			q.totalCount) *
																		100
																) + "%"
															}>
															<div
																style={{
																	width:
																		(1 -
																			q.yesCount /
																				q.totalCount) *
																			100 +
																		"%",
																}}></div>
														</div>
													)}
													<p>{q.question}</p>
												</div>
												<p className="list__end">
													{q.answer === 1 ? (
														<span>
															gemacht
															<CloseRoundedIcon
																htmlColor="#f26630"
																titleAccess="Hab ich schon mal gemacht"
															/>
														</span>
													) : (
														<span>
															noch nie
															<CheckRoundedIcon
																htmlColor="#75c049"
																titleAccess="Hab ich noch nie gemacht"
															/>
														</span>
													)}
													<button
														className="more_menu__button"
														title="Antwort zurücknehmen"
														onClick={(e) => {
															e.preventDefault();
															unsetAnswer(
																q.questionID
															);
														}}>
														<UndoRoundedIcon />
													</button>
												</p>
											</li>
										))}
								</ul>
							</div>
						)
					)}
				</div>
			)}
		</div>
	);
};

export default ZeitungIHNN;
