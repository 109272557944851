import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";

let menu = [
	{
		name: "Dashboard",
		url: "/",
		icon: <HomeRoundedIcon />,
	},

	{
		name: "Teams",
		url: "/teams",
		icon: <PeopleRoundedIcon />,
	},

	{
		name: "Chat",
		url: "/chat",
		icon: <ChatRoundedIcon />,
	},

	/*{
		name: "Abstimmungen",
		url: "/abstimmungen",
		icon: <PollRoundedIcon />,
	},*/

	{
		name: "Finanzierung",
		url: "/finanzierung",
		icon: <PaidRoundedIcon />,
	},

	{
		name: "Abi-Feier",
		url: "/feier",
		icon: <CelebrationRoundedIcon />,
	},

	{
		name: "Abi-Motto",
		url: "/motto",
		icon: <EditRoundedIcon />,
	},

	{
		name: "Abi-Zeitung",
		url: "/zeitung",
		icon: <NewspaperRoundedIcon />,
	},

	{
		name: "Einstellungen",
		url: "/einstellungen",
		icon: <SettingsRoundedIcon />,
	},
];

export function getMenu() {
	return menu;
}
