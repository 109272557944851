import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";

import logo from "../assets/logo.svg";
import UserContext from "../context/UserContext";
import api from "../functions/api";

const PasswortZuruecksetzen = () => {
	const { userid, token } = useParams();

	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");

	const [fehler, setFehler] = useState(false);

	const { setUser } = useContext(UserContext);

	function resetPassword(userID, token, password) {
		api.postPasswordReset(userID, token, password)
			.then((res) => {
				if (res.success) {
					setUser(res.user);
				} else {
					setFehler(true);
				}
			})
			.catch(() => setFehler(true));
	}

	return (
		<div className="beyond">
			<div className="beyond__box">
				<div className="beyond__box__logo">
					<img
						src={logo}
						alt="Logo des Abi-Planers"
						className="sidebar__logo-link__img"
						height="48px"
						width="48px"
					/>
					<h1 className="sidebar__logo-link__h">abiPlaner</h1>
				</div>

				<h2>Passwort zurücksetzen</h2>

				{
					// Fehlermeldung bei Fehler
					fehler ? (
						<div
							className="beyond__box__fehler beyond__box__fehler--zeigen"
							aria-hidden="false"
						>
							Es ist ein Fehler bei der Anmeldung aufgetreten.
						</div>
					) : (
						<div
							className="beyond__box__fehler"
							aria-hidden="true"
						></div>
					)
				}

				<form
					onSubmit={(e) => {
						e.preventDefault();
						resetPassword(userid, token, password);
					}}
				>
					<input
						type="password"
						name="password"
						id="password"
						placeholder="Neues Passwort"
						autoComplete="new-password"
						onChange={(e) => {
							setPassword(e.target.value);
						}}
					/>

					<input
						type="password"
						name="password-repeat"
						id="password-repeat"
						placeholder="Neues Passwort wiederholen"
						autoComplete="new-password"
						onChange={(e) => {
							setRepeatPassword(e.target.value);
						}}
					/>

					<button className="beyond__box__button">
						Passwort zurücksetzen
					</button>
				</form>
			</div>
			<footer>
				<a href="/datenschutz">Datenschutzerklärung</a>
			</footer>
		</div>
	);
};

export default PasswortZuruecksetzen;
