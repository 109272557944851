import React from 'react'

const Popup = ({children, close}) => {
    return (<>
        <div className="popup__abdunkeln" onClick={() => close()}></div>
        <div className="popup">
            {children}
        </div>
    </>)
}

export default Popup