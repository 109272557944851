import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { getAPI } from "../data/config";
import UserContext from "../context/UserContext";
import Abstimmung from "../components/abstimmung/Abstimmung";
import Textfeld from "../components/Textfeld";
import EditFinanzierungPopup from "../components/popups/EditFinanzierungPopup";
import api from "../functions/api";
import ProgressRing from "../components/layout/ProgressRing";
import MoreMenu from "../components/layout/MoreMenu";
import Page from "../components/layout/Page";
import ProgressBar from "../components/layout/ProgressBar";

const Finanzierung = () => {
	const user = useContext(UserContext).user;

	const [einnahmen, setEinnahmen] = useState([]);
	const [ausgaben, setAusgaben] = useState([]);
	const [gesammelt, setGesammelt] = useState(0);
	const [benoetigt, setBenoetigt] = useState(0);

	const [popup, setPopup] = useState({});

	function getFinanzierung() {
		api.getFinancing().then((res) => {
			var resBenoetigt = 0,
				resGesammelt = 0;
			setEinnahmen([]);
			setAusgaben([]);
			res.forEach((e) => {
				if (e.typ === 0) {
					setAusgaben((aus) => aus.concat(e));
					resBenoetigt += e.betrag;
				} else {
					setEinnahmen((ein) => ein.concat(e));
					resGesammelt += e.betrag;
				}
			});

			setBenoetigt(resBenoetigt);
			setGesammelt(resGesammelt);
		});
	}

	useEffect(() => {
		getFinanzierung();
	}, []);

	function postFinanzierung(text, betrag, dir) {
		api.postFinancing(dir, betrag, text).then(() => {
			getFinanzierung();
		});
	}

	return (
		<Page title="Finanzierung">
			<div className="financing">
				<div className="financing__overview">
					<div className="financing__overview__text">
						<div className="financing__overview__text__collected">
							<h3>{gesammelt} €</h3>
							<p>gesammelt</p>
						</div>
						<div className="financing__overview__text__needed">
							<h3>{benoetigt} €</h3>
							<p>benötigt</p>
						</div>
					</div>
					<ProgressBar progress={(gesammelt * 100) / benoetigt} />
				</div>

				<div className="financing__lists">
					<div className="financing__list__earnings">
						<h3>Einnahmen</h3>
						<ul className="list">
							{einnahmen.map((e) => {
								return (
									<li key={e.finanzierung_id}>
										{e.name}
										<div className="list__end">
											{e.betrag} €
											{user.role >= 2 ? (
												<MoreMenu
													items={[
														{
															title: "Löschen",
															action: () => {
																api.deleteFinancing(
																	e.finanzierung_id
																).then(() => {
																	getFinanzierung();
																});
															},
														},
													]}
												/>
											) : (
												<></>
											)}
										</div>
									</li>
								);
							})}
						</ul>

						{user.role >= 2 ? (
							<Textfeld
								typ="zahl"
								placeholder="Neue Einnahme"
								className="financing__add"
								onSubmit={(text, zahl) => {
									postFinanzierung(text, zahl, 1);
								}}
							/>
						) : (
							<></>
						)}
					</div>
					<div className="financing__list__spendings">
						<h3>Ausgaben</h3>
						<ul className="list">
							{ausgaben.map((e) => {
								return (
									<li key={e.finanzierung_id}>
										{e.name}
										<div className="list__end">
											{e.betrag} €
											{user.role >= 2 ? (
												<MoreMenu
													items={[
														{
															title: "Löschen",
															action: () => {
																api.deleteFinancing(
																	e.finanzierung_id
																).then(() => {
																	getFinanzierung();
																});
															},
														},
													]}
												/>
											) : (
												<></>
											)}
										</div>
									</li>
								);
							})}
						</ul>

						{user.role >= 2 ? (
							<Textfeld
								typ="zahl"
								placeholder="Neue Ausgabe"
								className="financing__add"
								onSubmit={(text, zahl) => {
									postFinanzierung(text, zahl, 0);
								}}
							/>
						) : (
							<></>
						)}
					</div>
				</div>

				<div className="financing__ideas">
					<h3>Vorschläge</h3>
					<Abstimmung role={2} />
				</div>
			</div>
			{Object.keys(popup).length !== 0 && (
				<EditFinanzierungPopup
					finanz={popup}
					close={() => {
						setPopup({});
						getFinanzierung();
					}}
				/>
			)}
		</Page>
	);
};

export default Finanzierung;
