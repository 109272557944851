import React from "react";
import { Link } from "react-router-dom";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import logo from "../../assets/logo.svg";
import TopbarNotifications from "./TopbarNotifications";
import TopbarProfile from "./TopbarProfile";

const Topbar = () => {
	return (
		<header className="topbar">
			<button
				type="button"
				className="topbar__menu mobile-only"
				title="Menü öffnen / schließen"
				onClick={() => {
					document
						.querySelector("body")
						.classList.toggle("sidebar--mobile");
				}}>
				<MenuRoundedIcon />
			</button>
			<Link to="/" className="topbar__branding">
				<img src={logo} alt="abiPlaner-Logo" />
				<h1>abiPlaner</h1>
			</Link>
			{false && <TopbarNotifications />}
			<TopbarProfile />
		</header>
	);
};

export default Topbar;
