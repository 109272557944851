import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Textfeld from "../../components/Textfeld";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import api from "../../functions/api";
import MoreMenu from "../../components/layout/MoreMenu";

const ZeitungBotschaftenUser = () => {
	const user = useContext(UserContext).user;

	const { userid } = useParams();
	const [botschaften, setBotschaften] = useState([]);
	const [bUser, setBUser] = useState({});

	function getUser() {
		api.getUser(userid).then((res) => {
			setBUser(res);
		});
	}

	function getBotschaften() {
		api.getNotes(userid).then((res) => {
			setBotschaften(res);
		});
	}

	function postBotschaft(botschaft) {
		api.postNote(userid, botschaft).then(() => {
			getBotschaften();
		});
	}

	function deleteNote(noteID) {
		api.deleteNote(noteID).then(() => {
			getBotschaften();
		});
	}

	useEffect(() => {
		getUser();
		getBotschaften();
	}, []);

	return (
		<div className="zeitung__botschaften zeitung__botschaften--user">
			<div className="zeitung__botschaften--user__user">
				<img
					src={
						bUser.picture === undefined
							? getAPI() + "public/images/standard.jpg"
							: getAPI() + "public/images/" + bUser.picture
					}
					alt="Profilbild"
					height="64px"
					width="64px"
					className="round"
				/>

				<div>
					<h2>{bUser.firstname + " " + bUser.lastname}</h2>
					<p>
						{botschaften.length +
							" " +
							(botschaften.length === 1
								? "Kommentar"
								: "Kommentare")}
					</p>
				</div>
			</div>
			<div className="zeitung__botschaften--user__botschaften">
				<ul className="grid">
					{botschaften.length === 0 ? (
						<p>Noch keine Kommentare.</p>
					) : (
						<>
							{botschaften.map((b) => {
								return (
									<li
										key={b.botschaft_id}
										className="card card--no-hover">
										{b.botschaft}
										{(user.role >= 2 ||
											user.userID === Number(userid)) && (
											<MoreMenu
												items={[
													{
														title: "Löschen",
														action: () => {
															if (
																window.confirm(
																	'Bist du dir sicher, dass du den Kommentar "' +
																		b.botschaft +
																		'" löschen möchtest?'
																)
															)
																deleteNote(
																	b.botschaft_id
																);
														},
													},
												]}
											/>
										)}
									</li>
								);
							})}
						</>
					)}
				</ul>
				{user.userID !== Number(userid) && (
					<>
						<p className="disclaimer">
							Deine Kommentare sind anonym, bitte bleibe
							respektvoll.
						</p>
						<Textfeld
							placeholder={
								"Neuer Kommentar über " + bUser.firstname
							}
							onSubmit={(text) => {
								postBotschaft(text);
							}}
							max={2048}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ZeitungBotschaftenUser;
