import React, { useContext, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import UserContext from "../../context/UserContext";
import EditRoundedButton from "@mui/icons-material/EditRounded";
import EditAbstimmungPopup from "../popups/EditAbstimmungPopup";

const AbstimmungInfos = ({ infos, reload }) => {
	const user = useContext(UserContext).user;
	const [popup, setPopup] = useState(false);

	if (infos.length === 0) return <></>;

	var hatEnde = false;
	var beendet = false;
	var ende = null;
	if (infos.end !== null) {
		ende = new Date(infos.end.replace(" ", "T"));
		if (isNaN(ende)) {
			hatEnde = false;
		} else {
			hatEnde = true;
		}
		const jetzt = new Date();

		beendet = ende < jetzt;
	}

	return (
		<>
			<div className="poll__info">
				{hatEnde && (
					<>
						<AccessTimeRoundedIcon />
						{beendet ? (
							<span>
								<b>Beendet.</b>
							</span>
						) : (
							<span>
								{"endet "}
								<b>
									<ReactTimeAgo date={ende} />
								</b>
							</span>
						)}
					</>
				)}
				{/*eventuell noch Gesamtzahl Votes*/}
			</div>
			{popup && (
				<EditAbstimmungPopup
					close={() => {
						setPopup(false);
						reload();
					}}
					abstimmung={infos[0]}
				/>
			)}
		</>
	);
};

export default AbstimmungInfos;
