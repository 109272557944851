import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import AbstimmungBlock from "../../components/abstimmung/AbstimmungBlock";
import Textfeld from "../../components/Textfeld";
import UserContext from "../../context/UserContext";
import api from "../../functions/api";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const ZeitungRankings = ({ group }) => {
	const user = useContext(UserContext).user;

	const [polls, setPolls] = useState([]);
	const [pollSuggestions, setPollSuggestions] = useState([]);

	async function getPolls() {
		api.getPollsByGroup(group).then((res) => {
			setPolls(res.filter((poll) => poll.confirmed));
			setPollSuggestions(res.filter((poll) => !poll.confirmed));
		});
	}

	useEffect(() => {
		getPolls();
	}, []);

	function newPoll(poll) {
		api.postPoll(poll, group).then(() => {
			setTimeout(() => getPolls(), 200);
		});
	}

	return (
		<div className="zeitung__umfragen">
			<section>
				<h3>Umfragen</h3>
				<ul className="list polls">
					{polls.map((poll) => {
						return (
							<AbstimmungBlock
								abstimmung={poll}
								key={poll.pollID}
								getAbstimmungen={() => {
									getPolls();
								}}
							/>
						);
					})}
				</ul>
			</section>
			<section className="zeitung__umfragen__vorschlaege">
				<h3>Vorschläge für weitere Umfragen</h3>
				{pollSuggestions.length === 0 && (
					<i>
						Aktuell sind keine Vorschläge für weitere Umfragen
						vorhanden. Wenn du denkst, dass eine Umfrage fehlt, füg
						sie einfach hinzu!
					</i>
				)}
				<ul className="list">
					{pollSuggestions.map((poll) => {
						return (
							<li key={poll.pollID}>
								<p>{poll.title}</p>
								{user.role >= 2 && (
									<div className="list__end">
										<button
											title="Akzeptieren"
											className="more_menu__button settings__users__waiting__accept"
											onClick={(e) => {
												e.preventDefault();
												api.acceptPollSuggestion(
													poll.pollID
												).then(() => {
													getPolls();
												});
											}}
										>
											<CheckCircleRoundedIcon />
										</button>
										<button
											className="more_menu__button settings__users__waiting__delete"
											title="Ablehnen"
											onClick={(e) => {
												e.preventDefault();
												api.deletePoll(
													poll.pollID
												).then(() => {
													getPolls();
												});
											}}
										>
											<DeleteRoundedIcon />
										</button>
									</div>
								)}
							</li>
						);
					})}
				</ul>

				<Textfeld
					placeholder="Neue Umfrage"
					onSubmit={(text) => {
						newPoll(text);
					}}
				/>
			</section>
		</div>
	);
};

export default ZeitungRankings;
