import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import UserContext from "../../context/UserContext";
import api from "../../functions/api";
import MoreMenu from "../../components/layout/MoreMenu";
import Textfeld from "../../components/Textfeld";
import { useNavigate } from "react-router-dom";

const ZeitungSteckbriefEdit = () => {
	const user = useContext(UserContext).user;

	const [categories, setCategories] = useState([]);

	const navigate = useNavigate();

	const getProfileCategories = () => {
		api.getProfileCategories().then((res) => {
			setCategories(res);
		});
	};

	useEffect(() => {
		getProfileCategories();
	}, []);

	const getTypeName = (typeID) => {
		switch (typeID) {
			case 0:
				return "Textfeld";
			case 1:
				return "Datum";
			default:
				return "Sonstiges";
		}
	};

	if (user.role < 2) {
		return null;
	}

	return (
		<div className="zeitung__steckbrief">
			<button
				id="editTemplate"
				className="zeitung__steckbrief__edit-button"
				onClick={(e) => {
					e.preventDefault();
					navigate("../steckbrief");
				}}
			>
				Zurück zum Steckbrief
			</button>
			<h3>Steckbrief-Vorlage bearbeiten</h3>
			<div className="zeitung__steckbrief__content">
				<ul className="list">
					{categories.map((category) => (
						<li
							key={category.categoryID}
							className="zeitung__steckbrief__category"
						>
							<b>{category.category}</b>
							<span className="zeitung__steckbrief__category__type">
								{getTypeName(category.type)}
							</span>
							<div className="list__end">
								<MoreMenu
									items={[
										{
											title: "Löschen",
											action: () => {
												api.deleteProfileCategory(
													category.categoryID
												).then(() => {
													getProfileCategories();
												});
											},
										},
									]}
								/>
							</div>
						</li>
					))}
				</ul>
			</div>
			<section className="zeitung__steckbrief__category__add">
				<h3>Kategorie hinzufügen</h3>
				<Textfeld
					typ="steckbrief-typ"
					placeholder="Neue Kategorie"
					onSubmit={(text, steckbrief_typ) => {
						api.postProfileCategory(text, steckbrief_typ).then(
							() => {
								getProfileCategories();
							}
						);
					}}
				/>
			</section>
		</div>
	);
};

export default ZeitungSteckbriefEdit;
