import axios from "axios";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";

const TopbarProfile = () => {
	const { user, setUser } = useContext(UserContext);

	const [flyoutVisible, setFlyoutVisible] = useState(false);

	function logout() {
		localStorage.removeItem("user");
		setUser();
	}

	function getRoleName(roleID) {
		switch (roleID) {
			case 1:
				return "Nutzer";
			case 2:
				return "Verwalter";
			case 3:
				return "Admin";
			default:
				return "Sonstiges";
		}
	}

	return (
		<div
			className="topbar__user clickable"
			onClick={() => {
				setFlyoutVisible(!flyoutVisible);
			}}
		>
			<img
				src={getAPI() + "public/images/" + user.picture}
				alt="Profilbild"
				className="round"
				height="32px"
			/>
			<p className="topbar__user__greeting desktop-only">
				<span className="topbar__user__greeting__welcome">
					{"Willkommen, "}
				</span>
				<span className="topbar__user__greeting__name">
					{user.firstname + "!"}
				</span>
			</p>
			<div
				className={
					flyoutVisible
						? "topbar__user__flyout topbar__user__flyout--visible"
						: "topbar__user__flyout"
				}
			>
				<div className="topbar__user__flyout__info">
					<img
						src={getAPI() + "public/images/" + user.picture}
						alt="Profilbild"
						className="round"
						height="96px"
						width="96px"
					/>
					<h3>{user.firstname + " " + user.lastname}</h3>
					<p>{user.email}</p>
					<p>{getRoleName(user.role)}</p>
				</div>
				<div className="topbar__user__flyout__buttons">
					<Link
						to="einstellungen/profil"
						className="button-full-width"
					>
						Mein Profil
					</Link>
					<button
						type="button"
						className="button-full-width"
						onClick={() => {
							logout();
						}}
					>
						Abmelden
					</button>
				</div>
			</div>
		</div>
	);
};

export default TopbarProfile;
