import React, { useContext, useState } from "react";

import logo from "../assets/logo.svg";
import UserContext from "../context/UserContext";
import api from "../functions/api";

// Tutorial: https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

const PasswortVergessen = () => {
	const [email, setEmail] = useState("");

	const [fehler, setFehler] = useState(false);
	const [success, setSuccess] = useState(false);

	const { setUser } = useContext(UserContext);

	function requestReset(email) {
		api.postRequestPasswordReset(email)
			.then((res) => {
				if (res.success) {
					setSuccess(true);
				} else {
					setFehler(true);
				}
			})
			.catch(() => setFehler(true));
	}

	return (
		<div className="beyond">
			<div className="beyond__box">
				<div className="beyond__box__logo">
					<img
						src={logo}
						alt="Logo des Abi-Planers"
						className="sidebar__logo-link__img"
						height="48px"
						width="48px"
					/>
					<h1 className="sidebar__logo-link__h">abiPlaner</h1>
				</div>

				<h2>Passwort zurücksetzen</h2>

				{success ? (
					<>
						<p>
							Bitte überprüfe dein E-Mail-Postfach und nutze den
							Link, um dein Passwort zurückzusetzen.
						</p>
					</>
				) : (
					<>
						<p>
							Wenn du dein Passwort vergessen hast, schicken wir
							dir per E-Mail einen Link zu, über den du dein
							Passwort ändern kannst.
						</p>

						{
							// Fehlermeldung bei Fehler
							fehler ? (
								<div
									className="beyond__box__fehler beyond__box__fehler--zeigen"
									aria-hidden="false"
								>
									Es ist ein Fehler aufgetreten. Bitte
									versuche es erneut.
								</div>
							) : (
								<div
									className="beyond__box__fehler"
									aria-hidden="true"
								></div>
							)
						}

						<form
							onSubmit={(e) => {
								e.preventDefault();
								requestReset(email);
							}}
						>
							<input
								type="text"
								name="mail"
								id="mail"
								placeholder="E-Mail"
								autoComplete="current-email"
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>

							<button className="beyond__box__button">
								Passwort zurücksetzen
							</button>
						</form>
					</>
				)}
			</div>
			<footer>
				<a href="/datenschutz">Datenschutzerklärung</a>
			</footer>
		</div>
	);
};

export default PasswortVergessen;
