import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAPI } from "../../data/config";
import api from "../../functions/api";
import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const ZeitungFreundestexte = () => {
	const [users, setUsers] = useState([]);

	function getUsers() {
		api.getWritersUsers().then((res) => {
			setUsers(res);
		});
	}

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<div className="zeitung__botschaften zeitung__botschaften--users">
			<div className="zeitung__description">
				<p>
					Hier kannst du dich deinen Freund*innen zuordnen, über die
					du entweder alleine oder mit anderen zusammen den Text für
					die Abi-Zeitung schreibst.
				</p>
			</div>
			<ul className="grid">
				{users.map((u) => {
					return (
						<li key={u.user_id}>
							<Link to={u.user_id + "/"} className="card">
								<img
									src={
										getAPI() +
										"public/images/" +
										(u.picture || "")
									}
									alt={"Profilbild von " + u.firstname}
									height="48px"
									width="48px"
									className="round"
								/>
								<div>
									<h4>{u.firstname + " " + u.lastname}</h4>
									<p>
										{u.ready ? (
											<>
												<CheckRoundedIcon color="success" />
												{"Text hochgeladen"}
											</>
										) : !u.writer_count ? (
											<>
												<PersonOffRoundedIcon color="error" />
												{"Keine Autor*innen"}
											</>
										) : u.writer_count === 1 ? (
											<>
												<PersonRoundedIcon />
												{"1 Autor*in"}
											</>
										) : u.writer_count === 2 ? (
											<>
												<PeopleAltRoundedIcon />
												{"2 Autor*innen"}
											</>
										) : (
											<>
												<GroupsRoundedIcon />
												{u.writer_count +
													" Autor*innen"}
											</>
										)}
									</p>
								</div>
							</Link>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default ZeitungFreundestexte;
