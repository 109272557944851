import React, { useEffect, useState } from "react";
import Textfeld from "../components/Textfeld";
import ChatMessage from "../components/chat/ChatMessage";
import api from "../functions/api";
import Page from "../components/layout/Page";

const Chat = () => {
	const [messages, setMessages] = useState([]);

	// const socket = useContext(SocketContext);

	useEffect(() => {
		api.getMessages().then((c) => {
			setMessages(c);
		});

		scrollDown();

		/*socket.on("new:chatMessage", (content) => {
			setMessages((messages) => [...messages, content]);
		});*/
	}, []);

	async function postMessage(message) {
		if (await api.postMessage(message)) {
			api.getMessages().then((c) => {
				setMessages(c);
			});
			scrollDown();
		} else {
			alert("Fehler");
		}
		//socket.emit("new:chatMessage", { text: message });
	}

	function scrollDown() {
		const chat = document.querySelector(".chat ul");
		chat.scrollTop = chat.scrollHeight;
	}

	return (
		<Page title="Chat">
			<div className="chat">
				<ul>
					{messages.map((msg) => {
						return (
							<ChatMessage message={msg} key={msg.message_id} />
						);
					})}
				</ul>

				<div className="chat__textbox">
					<Textfeld
						placeholder="Nachricht schreiben"
						onSubmit={async (text) => {
							await postMessage(text);
						}}
					/>
				</div>
			</div>
		</Page>
	);
};

export default Chat;
