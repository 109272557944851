function generateGreeting(name) {
	const hour = new Date().getHours();

	const randomEmoji = () => {
		const emojis = [];

		if (hour < 4 || hour > 19) {
			emojis.push("🌃", "🌉", "🌌", "🌙", "🌠");
		} else if (hour < 11) {
			emojis.push("🐓", "🐤", "🌻", "🌄", "🌅");
		} else {
			emojis.push("👋🏻", "✨", "😄", "🙃", "😇", "🫡", "🤌🏻", "👑", "🌈");
		}

		return emojis[Math.floor(Math.random() * emojis.length)];
	};

	const randomGreeting = () => {
		if (hour < 4 || hour > 19) {
			return "Guten Abend";
		} else if (hour < 11) {
			return "Guten Morgen";
		} else {
			const greetings = ["Hallo", "Willkommen", "Hi"];
			return greetings[Math.floor(Math.random() * greetings.length)];
		}
	};

	return (
		<>
			{randomGreeting() + ", "}
			<span style={{ whiteSpace: "nowrap" }}>
				{name}! {randomEmoji()}
			</span>
		</>
	);
}

export default generateGreeting;
