import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import EditUserPopup from "../../components/popups/EditUserPopup";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import api from "../../functions/api";
import { getAPI } from "../../data/config";
import MoreMenu from "../../components/layout/MoreMenu";

const EinstellungenBenutzer = () => {
	const user = useContext(UserContext).user;

	const [users, setUsers] = useState([]);
	const [wannaBeUsers, setWannaBeUsers] = useState([]);
	const [popup, setPopup] = useState({});

	function getUsers() {
		api.getUsers().then((res) => {
			setUsers([]);
			setWannaBeUsers([]);
			res.forEach((r) => {
				if (r.role > 0) {
					setUsers((u) => u.concat(r));
				} else {
					setWannaBeUsers((w) => w.concat(r));
				}
			});
		});
	}

	function deleteUser(uid) {
		api.deleteUser(uid).then((res) => {
			if (res.success) {
				getUsers();
			}
		});
	}

	function acceptUser(uid) {
		api.acceptUser(uid).then((res) => {
			getUsers();
		});
	}

	function getRollenname(rollenid) {
		switch (rollenid) {
			case 1:
				return "Nutzer";
			case 2:
				return "Verwalter";
			case 3:
				return "Admin";
			default:
				return "Sonstiges";
		}
	}

	const roles = [
		{ id: 1, name: "Nutzer" },
		{ id: 2, name: "Verwalter" },
		{ id: 3, name: "Admin" },
	];

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<div className="settings__users">
			{user.role >= 2 && wannaBeUsers.length > 0 && (
				<div className="settings__users__waiting">
					<h3>Warten auf Bestätigung</h3>
					<ul className="list">
						{wannaBeUsers.map((u) => {
							return (
								<li key={u.user_id}>
									{u.firstname + " " + u.lastname}

									<p>{u.email}</p>

									{user.role === 3 && (
										<div className="list__end">
											<button
												title="Akzeptieren"
												className="more_menu__button settings__users__waiting__accept"
												onClick={(e) => {
													e.preventDefault();
													acceptUser(u.user_id);
												}}
											>
												<CheckCircleRoundedIcon />
											</button>
											<button
												className="more_menu__button settings__users__waiting__delete"
												title="Ablehnen"
												onClick={(e) => {
													e.preventDefault();
													deleteUser(u.user_id);
												}}
											>
												<DeleteRoundedIcon />
											</button>
										</div>
									)}
								</li>
							);
						})}
					</ul>
				</div>
			)}

			<div className="settings__users__list">
				<h3>{users.length} Benutzer*innen</h3>
				<ul className="list">
					{users.map((u) => {
						return (
							<li key={u.user_id}>
								<img
									src={
										getAPI() + "public/images/" + u.picture
									}
									alt="Profilbild"
									width="32px"
									height="32px"
									className="round"
								/>
								{u.firstname + " " + u.lastname}

								{user.role !== 3 ||
								user.userID === u.user_id ? (
									<p className="settings__users__list__role">
										{getRollenname(u.role)}
									</p>
								) : (
									<select
										className="settings__users__list__role settings__users__list__role--select"
										onChange={(e) => {
											api.updateRole(
												u.user_id,
												e.target.value
											).then((res) => {
												if (res.success) {
													getUsers();
												}
											});
										}}
									>
										{roles.map((r) => {
											return (
												<option
													key={r.id}
													value={r.id}
													selected={r.id === u.role}
												>
													{r.name}
												</option>
											);
										})}
									</select>
								)}

								{user.role === 3 &&
									user.userID !== u.user_id && (
										<div className="list__end">
											<MoreMenu
												items={[
													{
														title: "Löschen",
														action: () => {
															if (
																window.confirm(
																	"Möchtest du den Nutzer " +
																		u.firstname +
																		" " +
																		u.lastname +
																		" wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
																)
															)
																deleteUser(
																	u.user_id
																);
														},
													},
												]}
											/>
										</div>
									)}
							</li>
						);
					})}
				</ul>
			</div>
			{Object.keys(popup).length !== 0 && (
				<EditUserPopup
					close={() => {
						setPopup({});
						getUsers();
					}}
					user={popup}
				/>
			)}
		</div>
	);
};

export default EinstellungenBenutzer;
