import React, { useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// import "./assets/scss/old.scss";
import "./assets/scss/index.scss";
import "./assets/scss/desktop.scss";
import "./assets/font/inter/inter.css";

import Layout from "./components/layout/Layout";
import UserContext from "./context/UserContext";
import Chat from "./pages/Chat";
import Dashboard from "./pages/Dashboard";
import Einstellungen from "./pages/Einstellungen";
import EinstellungenAbstimmungen from "./pages/einstellungen/EinstellungenAbstimmungen";
import EinstellungenBenutzer from "./pages/einstellungen/EinstellungenBenutzer";
import EinstellungenProfil from "./pages/einstellungen/EinstellungenProfil";
import EinstellungenProfilPasswort from "./pages/einstellungen/EinstellungenProfilPasswort";
import Feier from "./pages/Feier";
import FeierEssen from "./pages/feier/FeierEssen";
import FeierGetraenke from "./pages/feier/FeierGetraenke";
import FeierLocation from "./pages/feier/FeierLocation";
import FeierMusik from "./pages/feier/FeierMusik";
import Finanzierung from "./pages/Finanzierung";
import Login from "./pages/Login";
import PasswortZuruecksetzen from "./pages/PasswortZuruecksetzen";
import PasswortVergessen from "./pages/PasswortVergessen";
import Motto from "./pages/Motto";
import Register from "./pages/Register";
import Teams from "./pages/Teams";
import WarteAufBestaetigung from "./pages/WarteAufBestaetigung";
import Zeitung from "./pages/Zeitung";
import Datenschutz from "./pages/Datenschutz";
import ZeitungFreundestexte from "./pages/zeitung/ZeitungFreundestexte";
import TimeAgo from "javascript-time-ago";
import Abstimmungen from "./pages/Abstimmungen";
import de from "javascript-time-ago/locale/de.json";
import ZeitungBotschaften from "./pages/zeitung/ZeitungBotschaften";
import ZeitungBotschaftenUser from "./pages/zeitung/ZeitungBotschaftenUser";
import { SocketContext, socket } from "./context/SocketContext";
import EinstellungenProfilEdit from "./pages/einstellungen/EinstellungenProfilEdit";
import TeamNeu from "./pages/teams/TeamNeu";
import ZeitungIHNN from "./pages/zeitung/ZeitungIHNN";
import ZeitungFreundestexteUser from "./pages/zeitung/ZeitungsFreundestexteUser";
import ZeitungSchueler from "./pages/zeitung/ZeitungSchueler";
import ZeitungLehrer from "./pages/zeitung/ZeitungLehrer";
import ZeitungSteckbrief from "./pages/zeitung/ZeitungSteckbrief";
import EinstellungenBenachrichtigungen from "./pages/einstellungen/EinstellungenBenachrichtigungen";
import EinstellungenUeber from "./pages/einstellungen/EinstellungenUeber";
import { useEffect } from "react";
import api from "./functions/api";
import ZeitungSteckbriefEdit from "./pages/zeitung/ZeitungSteckbriefEdit";

TimeAgo.addDefaultLocale(de);

const useUser = () => {
	// const [cookies, setCookie, removeCookie] = useCookies(["user"]);
	// console.log(cookies);

	function getUser() {
		const userString = localStorage.getItem("user");
		try {
			return userString ? JSON.parse(userString) : undefined;
		} catch {
			localStorage.removeItem("user");
			return undefined;
		}
	}

	const [userState, setUserState] = useState(getUser());

	function saveUser(newUser) {
		localStorage.setItem("user", JSON.stringify(newUser));
		setUserState(newUser);
	}

	return {
		setUser: saveUser,
		user: userState,
	};
};

const App = () => {
	const { user, setUser } = useUser();

	const providerUser = useMemo(() => ({ user, setUser }), [user, setUser]);

	useEffect(() => {
		api.getMe()
			.then((res) => {
				if (res.success) {
					setUser(res.user);
				} else {
					setUser();
				}
			})
			.catch(() => {
				setUser();
			});
	}, []);

	return (
		<UserContext.Provider value={providerUser}>
			<SocketContext.Provider value={socket}>
				<BrowserRouter>
					{providerUser.user === undefined ? (
						// Wenn nicht eingeloggt
						<Routes>
							<Route path="login" element={<Login />} />
							<Route
								path="register/:token"
								element={<Register />}
							/>
							<Route
								path="reset/:userid/:token"
								element={<PasswortZuruecksetzen />}
							/>
							<Route
								path="reset"
								element={<PasswortVergessen />}
							/>
							<Route
								path="datenschutz"
								element={<Datenschutz />}
							/>
							<Route
								path="*"
								element={<Navigate replace to="/login" />}
							/>
						</Routes>
					) : (
						// Wenn eingeloggt
						<>
							{providerUser.user.role > 0 ? (
								<Routes>
									<Route path="/" element={<Layout />}>
										<Route index element={<Dashboard />} />
										<Route
											path="finanzierung"
											element={<Finanzierung />}
										/>
										<Route
											path="motto"
											element={<Motto />}
										/>
										<Route
											path="abstimmungen"
											element={<Abstimmungen />}
										/>
										<Route
											path="teams"
											element={<Teams />}
										/>
										<Route
											path="teams/neu"
											element={<TeamNeu />}
										/>

										<Route path="chat" element={<Chat />} />
										<Route
											path="zeitung"
											element={<Zeitung />}
										>
											<Route
												index
												element={
													<Navigate
														replace
														to="schuelerrankings"
													/>
												}
											/>
											<Route
												path="schuelerrankings"
												element={<ZeitungSchueler />}
											/>
											<Route
												path="lehrerrankings"
												element={<ZeitungLehrer />}
											/>
											<Route
												path="kommentare"
												element={<ZeitungBotschaften />}
											></Route>
											<Route
												path="kommentare/:userid"
												element={
													<ZeitungBotschaftenUser />
												}
											/>
											<Route
												path="freundestexte"
												element={
													<ZeitungFreundestexte />
												}
											/>
											<Route
												path="freundestexte/:userid"
												element={
													<ZeitungFreundestexteUser />
												}
											/>
											<Route
												path="steckbrief"
												element={<ZeitungSteckbrief />}
											/>
											<Route
												path="steckbrief/bearbeiten"
												element={
													<ZeitungSteckbriefEdit />
												}
											/>
											<Route
												path="ichhabnochnie"
												element={<ZeitungIHNN />}
											/>
										</Route>
										<Route path="feier" element={<Feier />}>
											<Route
												index
												element={
													<Navigate
														replace
														to="location"
													/>
												}
											/>
											<Route
												path="musik"
												element={<FeierMusik />}
											/>
											<Route
												path="location"
												element={<FeierLocation />}
											/>
											<Route
												path="essen"
												element={<FeierEssen />}
											/>
											<Route
												path="getraenke"
												element={<FeierGetraenke />}
											/>
										</Route>
										<Route
											path="einstellungen"
											element={<Einstellungen />}
										>
											<Route
												index
												element={
													<Navigate
														replace
														to="profil"
													/>
												}
											/>
											<Route
												path="profil"
												element={
													<EinstellungenProfil />
												}
											/>
											<Route
												path="profil/edit"
												element={
													<EinstellungenProfilEdit />
												}
											/>
											<Route
												path="profil/password"
												element={
													<EinstellungenProfilPasswort />
												}
											/>
											<Route
												path="benachrichtigungen"
												element={
													<EinstellungenBenachrichtigungen />
												}
											/>
											<Route
												path="benutzer"
												element={
													<EinstellungenBenutzer />
												}
											/>
											<Route
												path="abstimmungen"
												element={
													<EinstellungenAbstimmungen />
												}
											/>
											<Route
												path="ueber"
												element={<EinstellungenUeber />}
											/>
										</Route>
										<Route
											path="*"
											element={
												"Diese Seite existiert leider nicht."
											}
										/>
									</Route>
									<Route
										path="login"
										element={<Navigate replace to="/" />}
									/>
									<Route
										path="register"
										element={<Navigate replace to="/" />}
									/>
								</Routes>
							) : (
								<Routes>
									<Route
										path="warte"
										element={<WarteAufBestaetigung />}
									/>
									<Route
										path="datenschutz"
										element={<Datenschutz />}
									/>

									<Route
										path="*"
										element={
											<Navigate replace to="/warte" />
										}
									/>
								</Routes>
							)}
						</>
					)}
				</BrowserRouter>
			</SocketContext.Provider>
		</UserContext.Provider>
	);
};

export default App;
