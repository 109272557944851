import React, { useContext } from "react";
import { getAPI } from "../../data/config";
import ReactTimeAgo from "react-time-ago";
import UserContext from "../../context/UserContext";

const ChatMessage = ({ message }) => {
	const date = Date.parse(message.zeit.replace(" ", "T"));
	const userID = useContext(UserContext).user.userID;

	return (
		<li
			/* onContextMenu={(e) => {
				e.preventDefault();
				alert(message.text);
			}} */
			className={
				userID === message.user.user_id
					? "chat__message chat__message--you"
					: "chat__message"
			}>
			<img
				src={getAPI() + "public/images/" + message.user.picture}
				alt="Profilbild"
				width="48px"
				height="48px"
				className="chat__message__picture"
			/>
			<div className="chat__message__content">
				<p className="chat__message__from">
					<span className="chat__message__author">
						{message.user.firstname} {message.user.lastname}
					</span>
					<span className="chat__message__date">
						<ReactTimeAgo date={date} />
					</span>
				</p>
				<p className="chat__message__body">{message.text}</p>
			</div>
		</li>
	);
};

export default ChatMessage;
