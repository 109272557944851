import React, { useContext, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import UserContext from "../context/UserContext";
import api from "../functions/api";
import {
	checkPassword,
	passwordFailReason,
} from "../functions/passwordStrength";

const Register = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState("");
	const [passwort, setPasswort] = useState("");
	const [vorname, setVorname] = useState("");
	const [nachname, setNachname] = useState("");

	const [fehler, setFehler] = useState(false);
	const [passwortHinweis, setPasswortHinweis] = useState("");
	const { setUser } = useContext(UserContext);

	const { token } = useParams();

	function register() {
		if (!checkPassword(passwort)) {
			setPasswortHinweis(passwordFailReason(passwort));
			return;
		}

		api.postUser(vorname, nachname, email, passwort, token)
			.then((res) => {
				if (res.success) {
					api.postLogin(email, passwort)
						.then((res2) => {
							if (res2.success) {
								setUser(res2.user);
							} else {
								navigate("/login");
							}
						})
						.catch(() => setFehler(true));
					navigate("/");
				} else {
					setFehler(true);
				}
			})
			.catch(() => {
				setFehler(true);
			});
	}

	return (
		<div className="beyond">
			<div className="beyond__box">
				<div className="beyond__box__logo">
					<img
						src={logo}
						alt="Logo des Abi-Planers"
						className="sidebar__logo-link__img"
						height="48px"
						width="48px"
					/>
					<h1 className="sidebar__logo-link__h">abiPlaner</h1>
				</div>

				<h2>Registrieren</h2>
				<Link to="/login">Einloggen?</Link>

				{
					// Fehlermeldung bei Fehler
					fehler ? (
						<div
							className="beyond__box__fehler login__box__fehler--zeigen"
							aria-hidden="false"
						>
							Es ist ein Fehler bei der Registrierung aufgetreten.
						</div>
					) : (
						<div
							className="beyond__box__fehler"
							aria-hidden="true"
						></div>
					)
				}

				<form
					onSubmit={(e) => {
						e.preventDefault();
						register();
					}}
				>
					<input
						type="text"
						name="vorname"
						id="vorname"
						placeholder="Vorname"
						required
						onChange={(e) => {
							setVorname(e.target.value);
						}}
					/>

					<input
						type="text"
						name="nachname"
						id="nachname"
						placeholder="Nachname"
						required
						onChange={(e) => {
							setNachname(e.target.value);
						}}
					/>

					<input
						type="email"
						name="mail"
						id="mail"
						placeholder="E-Mail"
						autoComplete="current-email"
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>

					<input
						type="password"
						name="password"
						id="password"
						placeholder="Passwort"
						required
						onChange={(e) => {
							setPasswort(e.target.value);
						}}
						minLength={8}
					/>

					<div>
						<input type="checkbox" required id="privacy" />
						<label htmlFor="privacy">
							Ich akzeptiere die{" "}
							<a href="/datenschutz">Datenschutzerklärung</a>.
						</label>
					</div>

					{passwortHinweis !== "" && <b>{passwortHinweis}</b>}

					<button className="beyond__box__button">
						Registrieren
					</button>
				</form>
			</div>
			<footer>
				<a href="/datenschutz">Datenschutzerklärung</a>
			</footer>
		</div>
	);
};

export default Register;
