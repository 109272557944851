import React from "react";
import { Outlet } from "react-router-dom";
import Page from "../components/layout/Page";

const Feier = () => {
	const navigation = [
		{
			to: "location",
			title: "Location",
		},
		{ to: "musik", title: "Musik" },
		{ to: "essen", title: "Essen" },
		{ to: "getraenke", title: "Getränke" },
	];

	return (
		<Page title="Abi-Feier" navigation={navigation}>
			<Outlet />
		</Page>
	);
};

export default Feier;
