import ReactTimeAgo from "react-time-ago";

export const generateRecommendations = (r, userID) => {
	const recommendations = [];

	if (r.length === 0) {
		return [
			{
				title: "Keine Vorschläge",
				body: <span>Sieh dich ein bisschen um!</span>,
			},
		];
	}

	r.forEach((i) => {
		if (i.type === "profilepicture") {
			recommendations.push({
				title: "Lade ein Foto hoch",
				body: (
					<span>
						Du hast noch kein Profilbild festgelegt. Leg ein Bild
						fest und sei einzigartig!
					</span>
				),
				link: "/einstellungen/profil/edit",
			});
		} else if (i.type === "note") {
			recommendations.push({
				title: "Jemand hat dir eine neue Botschaft geschrieben",
				body: <span>{i.item.botschaft}</span>,
				link: "/zeitung/kommentare/" + userID,
			});
		} else if (i.type === "soonEndingPoll") {
			recommendations.push({
				title: "Stimm jetzt noch ab!",
				body: (
					<span>
						Die Abstimmung "{i.item.title}" endet{" "}
						<ReactTimeAgo date={i.item.end} />. Möchtest du jetzt
						noch abstimmen?
					</span>
				),
			});
		} else if (i.type === "team") {
			recommendations.push({
				title: "Hilf bei diesem Team mit!",
				body: (
					<span>
						Das Team {i.item.name} hat
						{i.item.memberCount === 0
							? " noch keine "
							: " erst " + i.item.memberCount + " "}
						{i.item.memberCount === 1 ? "Mitglied" : "Mitglieder"}.
						Trag dich ein und mach mit!
					</span>
				),
				link: "/teams",
			});
		} else if (i.type === "profile") {
			recommendations.push({
				title: "Füll deinen Steckbrief aus!",
				body: (
					<span>
						{"Du hast noch " +
							i.item.count +
							(i.item.count === 1
								? " leeres Feld "
								: " leere Felder ") +
							"in deinem Steckbrief. Fülle " +
							(i.item.count === 1 ? "es" : "sie") +
							" jetzt aus!"}
					</span>
				),
				link: "/zeitung/steckbrief",
			});
		} else if (i.type === "nhie") {
			recommendations.push({
				title: 'Mach mit bei "Ich hab noch nie!"',
				body: (
					<span>
						{"Du hast " +
							i.item.count +
							(i.item.count === 1 ? " Frage " : " Fragen ") +
							'bei "Ich hab noch nie" noch nicht beantwort. Los geht\'s!'}
					</span>
				),
				link: "/zeitung/ichhabnochnie",
			});
		}
	});

	return recommendations;
};
