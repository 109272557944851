import React from "react";
import logo from "../assets/logo.svg";

const Datenschutz = () => {
	return (
		<div className="beyond">
			<div className="beyond__box datenschutz">
				<div className="beyond__box__header">
					<a className="beyond__box__link" href="/">
						Zurück
					</a>
					<div className="beyond__box__logo">
						<img
							src={logo}
							alt="Logo des Abi-Planers"
							className="sidebar__logo-link__img"
							height="48px"
							width="48px"
						/>
						<h1 className="sidebar__logo-link__h">abiPlaner</h1>
					</div>
				</div>
				<h2>Datenschutzerklärung</h2>
				<p>Der Verantwortliche im Sinne der DSGVO ist</p>
				<address>
					<b>Constantin Birken</b>
					<br />
					Hildburghäuser Straße 3
					<br />
					52146 Würselen
					<br />
					Internet:{" "}
					<a href="https://www.constantinbirken.de/">
						www.constantinbirken.de
					</a>
					<br />
					E-Mail:{" "}
					<a href="mailto:kontakt@constantinbirken.de">
						kontakt@constantinbirken.de
					</a>
				</address>
				<h3>Allgemeines zur Datenverarbeitung</h3>
				<p>
					Der abiPlaner erhebt und verarbeitet personenbezogene Daten
					grundsätzlich nur, soweit dies zur Funktionsweise der
					Plattform erforderlich ist.
					<br />
					Ihre Daten werden von uns niemals für Werbezwecke verwendet.
				</p>
				<p>
					Die Verarbeitung personenbezogener Daten erfolgt
					ausschließlich mit Ihrer Einwilligung. Diese Einwilligung
					ist bei der Registrierung eines Accounts zwangsläufig
					erforderlich.
				</p>
				<p>
					Wenn Sie sich dazu entscheiden, Ihren Account zu löschen,
					werden alle mit Ihnen in Verbindung stehenden Daten
					gelöscht.
				</p>
				<h3>Welche Daten wir sammeln</h3>
				<p>
					Ihr Datenschutz hat für den abiPlaner eine sehr hohe
					Priorität. Aus diesem Grund sammeln wir keine Daten für
					Werbe- oder Statistikzwecke sowie keine Daten, die für
					unsere Dienste nicht zwingend erforderlich sind.
				</p>
				<h4>Bei der Registrierung</h4>
				<p>
					Bei der Registrierung verarbeiten wir Ihren Vor- und
					Nachnamen, Ihre E-Mail-Adresse und das von Ihnen festgelegte
					Passwort. Ohne die wahrheitsgemäße Angabe dieser Daten ist
					eine Nutzung des abiPlaners nicht möglich.
				</p>
				<h4>Bei Nutzung der Plattform</h4>
				<p>
					Bei der Nutzung des abiPlaners werden standardmäßig keine
					Daten gesammelt. Sobald Sie sich aber dazu entscheiden,
					einen Beitrag zu posten, zu bewerten oder ein Profilbild
					festzulegen, werden Daten gespeichert.
					<br />
					Bei diesen handelt es sich um Ihre Nutzer-ID, anhand derer
					Ihr Account eindeutig identifiziert werden kann, um die
					Inhalte Ihres Beitrags und um den Kontext, das heißt wo
					genau dieser Beitrag erscheinen soll.
					<br />
					Beim Festlegen eines Profilbilds wird außerdem das von Ihnen
					hochgeladene Bild gespeichert und kann von allen anderen
					Nutzer*innen angesehen werden.
				</p>
				<h3>Was mit Ihren Daten passiert</h3>
				<p>
					Ihre Daten werden ausschließlich auf Servern mit Standort
					innerhalb von Deutschland gespeichert und verarbeitet. Alle
					Passwörter werden sicher und verschlüsselt gespeichert,
					sodass ein Auslesen dieser nicht möglich ist.
				</p>
				<p>
					Die Beiträge, die Sie posten, werden allen registrierten
					Nutzer*innen auf der Plattform angezeigt. Dies betrifft auch
					Nutzer*innen, deren Account erst nach der Erstellung Ihres
					Beitrags Zugriff auf die Plattform erhält. Bei anonymen
					Beiträgen wird Ihre Nutzer-ID und Ihr Nutzername zwar nicht
					öffentlich angezeigt, aber dennoch gespeichert. Der
					Plattforminhaber behält sich das Recht vor, im Falle von
					Mobbing oder Spam die Urheber*innen anonymer Beiträge zum
					Schutz der Plattform und anderer Nutzer*innen ausfindig zu
					machen und die jeweiligen Accounts zu sperren oder zu
					löschen.
				</p>
			</div>
		</div>
	);
};

export default Datenschutz;
