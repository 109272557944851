import React, { useState } from "react";
import api from "../../functions/api";
import Page from "../../components/layout/Page";
import { useNavigate } from "react-router-dom";

const TeamNeu = () => {
	const [name, setName] = useState("");
	const navigate = useNavigate();

	async function createTeam(name) {
		await api.postTeam(name);
	}

	return (
		<Page title="Team erstellen">
			<form
				onSubmit={async (e) => {
					e.preventDefault();
					await createTeam(name);
					setName("");
					navigate("/teams");
				}}>
				<input
					type="text"
					name="name"
					id="name"
					placeholder="Name"
					className="textfeld"
					value={name}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>

				<button>Hinzufügen</button>
			</form>
		</Page>
	);
};

export default TeamNeu;
