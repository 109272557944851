import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import UserContext from "../../context/UserContext";
import api from "../../functions/api";
import ZeitungSteckbriefBlock from "./ZeitungSteckbriefBlock";
import { useNavigate } from "react-router-dom";

const ZeitungSteckbrief = () => {
	const user = useContext(UserContext).user;

	const [profile, setProfile] = useState([]);
	const [initialAnswers, setInitialAnswers] = useState([]);

	const [profiles, setProfiles] = useState();
	const [profilesLoaded, setProfilesLoaded] = useState(false);

	const navigate = useNavigate();

	const lkList1 = [
		{ id: "deu", name: "Deutsch (Bausch)" },
		{ id: "eng", name: "Englisch (Hammes)" },
		{ id: "erd", name: "Erdkunde (Paulußen)" },
		{ id: "mat", name: "Mathe (Laumen)" },
		{ id: "pae", name: "Pädagogik (Renn)" },
	];

	const lkList2 = [
		{
			id: "bio",
			name: "Biologie (Ebel)",
		},
		{
			id: "deu",
			name: "Deutsch (Schüller)",
		},
		{
			id: "eng",
			name: "Englisch (Müsseler)",
		},
		{
			id: "pae",
			name: "Pädagogik (Koch)",
		},
		{
			id: "phy",
			name: "Physik (Maas)",
		},
	];

	function getProfile() {
		api.getProfile().then((res) => {
			setProfile(
				res.map((category) => {
					if (category.type !== 2) {
						return {
							categoryID: category.categoryID,
							category: category.category,
							type: category.type,
							answer: category.answer || "",
						};
					} else {
						return {
							categoryID: category.categoryID,
							category: category.category,
							type: category.type,
							answer:
								category.answer !== null
									? JSON.parse(category.answer)
									: { lk1: "0", lk2: "0" },
						};
					}
				})
			);
			setInitialAnswers(
				res.map((category) => {
					if (category.type !== 2) {
						return {
							categoryID: category.categoryID,
							answer: category.answer || "",
						};
					} else {
						return {
							categoryID: category.categoryID,
							answer:
								category.answer !== null
									? JSON.parse(category.answer)
									: { lk1: "0", lk2: "0" },
						};
					}
				})
			);
		});
	}

	async function getProfiles() {
		await api.getProfiles().then((res) => {
			const dateCategories = [];
			const lkCategories = [];

			res.categories.forEach((category) => {
				if (category.type === 1)
					dateCategories.push(category.categoryID);
				else if (category.type === 2)
					lkCategories.push(category.categoryID);
			});

			Object.keys(res.answers).forEach((profile) => {
				res.answers[profile].forEach((category) => {
					if (dateCategories.includes(category.categoryID)) {
						category.answer = new Date(
							category.answer
						).toLocaleDateString();
					}
					if (lkCategories.includes(category.categoryID)) {
						const lks = JSON.parse(category.answer);
						category.answer =
							lkList1.find((lk) => lk.id === lks.lk1).name +
							", " +
							lkList2.find((lk) => lk.id === lks.lk2).name;
					}
				});
			});

			setProfiles(res);
		});
	}

	async function saveProfile() {
		// compare profile to initial answers
		// if there are differences, send them to the server
		let changedAnswers = [];
		profile.forEach((category, index) => {
			if (category.type === 2) {
				if (
					category.answer.lk1 !== initialAnswers[index].answer.lk1 ||
					category.answer.lk2 !== initialAnswers[index].answer.lk2
				) {
					changedAnswers.push({
						categoryID: category.categoryID,
						answer: JSON.stringify(category.answer),
					});
				}
			} else {
				if (category.answer !== initialAnswers[index].answer) {
					changedAnswers.push({
						categoryID: category.categoryID,
						answer: category.answer,
					});
				}
			}
		});

		if (changedAnswers.length > 0) {
			await api.updateProfile(changedAnswers);
			document.getElementById("saveAnswers").style.fontWeight = "normal";
			setInitialAnswers(
				profile.map((category) => {
					return {
						categoryID: category.categoryID,
						answer: category.answer || "",
					};
				})
			);
		}
	}

	async function checkForChanges() {
		// compare profile to initial answers
		let changesFound = false;

		profile.forEach((category, index) => {
			if (category.type === 2) {
				if (category.answer.lk1 !== initialAnswers[index].answer.lk1) {
					changesFound = true;
				}
				if (category.answer.lk2 !== initialAnswers[index].answer.lk2) {
					changesFound = true;
				}
			} else {
				if (category.answer !== initialAnswers[index].answer) {
					changesFound = true;
				}
			}
		});

		if (changesFound) {
			document.getElementById("saveAnswers").style.fontWeight = "bold";
		} else {
			document.getElementById("saveAnswers").style.fontWeight = "normal";
		}
	}

	useEffect(() => {
		getProfile();

		if (user.role >= 2) {
			getProfiles().then(() => {
				setProfilesLoaded(true);
			});
		}
	}, []);

	return (
		<div className="zeitung__steckbrief">
			{user.role >= 2 && (
				<button
					id="editTemplate"
					className="zeitung__steckbrief__edit-button"
					onClick={(e) => {
						e.preventDefault();
						navigate("bearbeiten");
					}}
				>
					Steckbrief-Vorlage bearbeiten
				</button>
			)}
			<div className="zeitung__description">
				<p>
					Das ist dein Steckbrief! Hier kannst du – wie in einem
					Freundebuch – kurz einige Kategorien über dich ausfüllen.
					Diese werden dann auf deiner Seite in der Abi-Zeitung
					veröffentlicht.
				</p>
			</div>
			<div>
				<form className="zeitung__steckbrief__form">
					{profile.map((category) => {
						switch (category.type) {
							case 0:
								return (
									<React.Fragment key={category.categoryID}>
										<label htmlFor={category.categoryID}>
											{category.category}:
										</label>
										<input
											value={category.answer}
											id={category.categoryID}
											name={category.category}
											type="text"
											maxLength="128"
											className="textfeld"
											onChange={(e) => {
												setProfile(
													profile.map((item) => {
														if (
															item.categoryID ===
															category.categoryID
														) {
															item.answer =
																e.target.value;
														}
														return item;
													})
												);
												checkForChanges();
											}}
										/>
									</React.Fragment>
								);
							case 1:
								return (
									<React.Fragment key={category.categoryID}>
										<label htmlFor={category.categoryID}>
											{category.category}:
										</label>
										<input
											value={category.answer}
											id={category.categoryID}
											name={category.category}
											type="date"
											className="textfeld"
											onChange={(e) => {
												setProfile(
													profile.map((item) => {
														if (
															item.categoryID ===
															category.categoryID
														) {
															item.answer =
																e.target.value;
														}
														return item;
													})
												);
												checkForChanges();
											}}
										/>
									</React.Fragment>
								);
							case 2:
								return (
									<React.Fragment key={category.categoryID}>
										<label
											htmlFor={category.categoryID + "-1"}
										>
											{category.category}:
										</label>
										<div className="two-selections">
											<select
												className="textfeld"
												id={category.categoryID + "-1"}
												title={category.category + " 1"}
												defaultValue={
													category.answer.lk1
												}
												onChange={(e) => {
													setProfile(
														profile.map((item) => {
															if (
																item.categoryID ===
																category.categoryID
															) {
																item.answer = {
																	lk1: e
																		.target
																		.value,
																	lk2: item
																		.answer
																		.lk2,
																};
															}
															return item;
														})
													);
													checkForChanges();
												}}
											>
												<option value="0" disabled>
													-- bitte auswählen --
												</option>
												{lkList1.map((lk) => {
													return (
														<option
															key={lk.id}
															value={lk.id}
														>
															{lk.name}
														</option>
													);
												})}
											</select>
											<select
												className="textfeld"
												id={category.categoryID + "-2"}
												title={category.category + " 2"}
												defaultValue={
													category.answer.lk2
												}
												onChange={(e) => {
													setProfile(
														profile.map((item) => {
															if (
																item.categoryID ===
																category.categoryID
															) {
																item.answer = {
																	lk1: item
																		.answer
																		.lk1,
																	lk2: e
																		.target
																		.value,
																};
															}
															return item;
														})
													);
													checkForChanges();
												}}
											>
												<option value="0" disabled>
													-- bitte auswählen --
												</option>
												{lkList2.map((lk) => {
													return (
														<option
															key={lk.id}
															value={lk.id}
														>
															{lk.name}
														</option>
													);
												})}
											</select>
										</div>
									</React.Fragment>
								);
							default:
								return (
									<React.Fragment key={category.categoryID}>
										<label>{category.category}:</label>
										<p>
											Dieser Antworttyp wird in deiner
											Version des abiPlaners noch nicht
											unterstützt. Bitte lade die Seite
											neu.
										</p>
									</React.Fragment>
								);
						}
					})}
					<button
						type="submit"
						id="saveAnswers"
						onClick={(e) => {
							e.preventDefault();
							saveProfile();
						}}
					>
						Speichern
					</button>
				</form>
			</div>
			{user.role >= 2 && profilesLoaded && (
				<div>
					<h3>Andere Steckbriefe</h3>
					<ul className="list polls">
						{profiles.users.map((user) => {
							return (
								<ZeitungSteckbriefBlock
									key={user.user_id}
									user={user}
									profile={
										profiles.answers[user.user_id] || []
									}
									categories={profiles.categories}
								/>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default ZeitungSteckbrief;
