import React from "react";
import AbstimmungEintrag from "./AbstimmungEintrag";

const AbstimmungListe = ({ eintraege, getAbstimmung, beendet }) => {
	return (
		<ul className="list">
			{eintraege.length === 0 ? (
				<li>
					Es sind noch keine Vorschläge vorhanden. Schreib den ersten!
				</li>
			) : (
				eintraege.map((eintrag) => {
					return (
						<AbstimmungEintrag
							eintrag={eintrag}
							getAbstimmung={() => getAbstimmung()}
							key={eintrag.nominationID}
							beendet={beendet}
						/>
					);
				})
			)}
		</ul>
	);
};

export default AbstimmungListe;
