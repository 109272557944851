import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Page from "../components/layout/Page";
import UserContext from "../context/UserContext";

const Einstellungen = () => {
	const user = useContext(UserContext).user;

	const navigation = [
		{ to: "profil", title: "Profil" },
		/* { to: "benachrichtigungen", title: "Benachrichtigungen" }, */
		{ to: "benutzer", title: "Benutzer" },
	];
	if (user.role > 1) {
		navigation.push({ to: "abstimmungen", title: "Abstimmungen" });
	}
	navigation.push({ to: "ueber", title: "Über" });

	return (
		<Page title="Einstellungen" navigation={navigation}>
			<Outlet />
		</Page>
	);
};

export default Einstellungen;
