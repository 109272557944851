function checkPassword(password) {
	return (
		/[a-z]/.test(password) &&
		/[A-Z]/.test(password) &&
		/[0-9]/.test(password) &&
		password.length >= 8
	);
}

function passwordFailReason(password) {
	if (!/[a-z]/.test(password)) {
		return "Das Passwort muss mindestens einen Kleinbuchstaben enthalten.";
	}
	if (!/[A-Z]/.test(password)) {
		return "Das Passwort muss mindestens einen Großbuchstaben enthalten.";
	}
	if (!/[0-9]/.test(password)) {
		return "Das Passwort muss mindestens eine Zahl enthalten.";
	}
	if (password.length < 8) {
		return "Das Passwort muss mindestens 8 Zeichen lang sein.";
	}
}

function checkPasswordRepeat(password, passwordRepeat) {
	return password === passwordRepeat && checkPassword(password);
}

function checkPasswordRepeatReason(password, passwordRepeat) {
	if (!checkPassword(password)) {
		return passwordFailReason(password);
	}
	if (password !== passwordRepeat) {
		return "Die Passwörter stimmen nicht überein.";
	}
}

export {
	checkPassword,
	passwordFailReason,
	checkPasswordRepeat,
	checkPasswordRepeatReason,
};
