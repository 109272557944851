import axios from "axios";
import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import EditSelfPopup from "../../components/popups/EditSelfPopup";
import { Link } from "react-router-dom";

const EinstellungenProfil = () => {
	const { user, setUser } = useContext(UserContext);
	const [popup, setPopup] = useState(false);

	function logout() {
		axios.get(getAPI() + "user/logout.php?id=" + user.token).then(() => {
			setUser();
		});
	}

	function getRoleName(roleID) {
		switch (roleID) {
			case 1:
				return "Nutzer";
			case 2:
				return "Verwalter";
			case 3:
				return "Admin";
			default:
				return "Sonstiges";
		}
	}

	function getUserData() {
		axios
			.get(getAPI() + "actions/get.php?action=user&id=" + user.token)
			.then((res) => {
				setUser({
					token: user.token,
					...res.data,
				});
			});
	}

	return (
		<div className="settings__profile">
			<div className="settings__profile__left">
				<img
					src={getAPI() + "public/images/" + user.picture}
					alt="Profilbild"
					className="settings__profile__picture round"
					height="128px"
					width="128px"
				/>
			</div>

			<div className="settings__profile__right">
				<h3>{user.firstname + " " + user.lastname}</h3>
				<p>
					{getRoleName(user.role)} |{" "}
					<a href={"mailto:" + user.email}>{user.email}</a>
				</p>
				<p>
					{user.class.school} | Abiturjahrgang {user.class.year}
				</p>
				<div className="settings__profile__right__buttons">
					<Link to="edit" className="settings__profile__edit__button">
						<EditRoundedIcon />
						Profil bearbeiten
					</Link>
					<Link
						to="password"
						className="settings__profile__edit__button"
					>
						<KeyRoundedIcon />
						Passwort ändern
					</Link>
				</div>
			</div>
		</div>
	);
};

export default EinstellungenProfil;
