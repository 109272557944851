import React, { useEffect, useState } from "react";
import api from "../../functions/api";

const EinstellungenAbstimmungen = () => {
	const [abstimmungen, setAbstimmungen] = useState([]);

	function getAbstimmungen() {
		api.getPolls().then((res) => {
			setAbstimmungen(res);
		});
	}

	useEffect(() => {
		getAbstimmungen();
	}, []);

	return (
		<ul className="list">
			{abstimmungen.map((abstimmung) => {
				return <li key={abstimmung.pollID}>{abstimmung.title}</li>;
			})}
		</ul>
	);
};

export default EinstellungenAbstimmungen;
