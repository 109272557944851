import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import ActivityList from "../components/dashboard/ActivityList";
import Cards from "../components/layout/Cards";
import UserContext from "../context/UserContext";
import { generateRecommendations } from "../data/recommendations";
import api from "../functions/api";
import generateGreeting from "../functions/greeting";

const Dashboard = () => {
	const user = useContext(UserContext).user;

	const [recommendations, setRecommendations] = useState([]);
	const [recentActivity, setRecentActivity] = useState([]);
	const [upcomingEvents, setUpcomingEvents] = useState([]);
	const [greeting, setGreeting] = useState("");

	useEffect(() => {
		setGreeting(generateGreeting(user.firstname));
		api.getDashboard().then((d) => {
			if (d.recommendations) {
				setRecommendations(
					generateRecommendations(d.recommendations, user.userID)
				);
			}
			if (d.recentActivity) {
				setRecentActivity(
					d.recentActivity.sort(
						(a, b) =>
							new Date(b.date).getTime() -
							new Date(a.date).getTime()
					)
				);
			}
			if (d.upcomingEvents) setUpcomingEvents(d.upcomingEvents);
		});
	}, [user.firstname]);

	useLayoutEffect(() => {
		document.title = "abiPlaner";
	}, []);

	return (
		<>
			<h2 className="title_buttons__title">{greeting}</h2>
			<section>
				<h3>Vorschläge</h3>
				<Cards cards={recommendations} />
			</section>
			<div className="dashboard__timeline">
				<section>
					<h3>Letzte Aktivität</h3>
					<ActivityList
						list={recentActivity}
						placeholder={
							"Keine Aktivität in den letzten drei Tagen"
						}
					/>
				</section>
				<section>
					<h3>Nächste Termine</h3>
					<ul className="list list--date">
						{upcomingEvents.length === 0 ? (
							<i>Keine anstehenden Termine</i>
						) : (
							upcomingEvents.map((e) => (
								<li key={e.id}>
									<span className="date">
										{new Date(e.date).getDate() +
											"." +
											(new Date(e.date).getMonth() + 1) +
											"."}
									</span>
									{e.name}
								</li>
							))
						)}
					</ul>
				</section>
			</div>
		</>
	);
};

export default Dashboard;
