import React from "react";

import Sidebar from "../template/Sidebar";

import { Outlet } from "react-router-dom";
import Alert from "../template/Alert";
import Topbar from "../topbar/Topbar";

const Layout = () => {
	return (
		<div className="abiplaner">
			<Sidebar />
			{false && <Alert alert="Willkommen beim abiPlaner 2.0" />}
			<Topbar />
			<main>
				<Outlet />
			</main>
		</div>
	);
};

export default Layout;
