import React, { useEffect } from "react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

const ActivityList = ({ list, placeholder }) => {
	const [expanded, setExpanded] = React.useState(false);

	const [items, setItems] = React.useState(list.slice(0, 10));

	useEffect(() => {
		if (expanded) {
			setItems(list);
		} else {
			setItems(list.slice(0, 10));
		}
	}, [expanded, list]);

	return (
		<ul className="list list--date">
			{list.length === 0 ? (
				<i>{placeholder}</i>
			) : (
				items.map((e) => (
					<li key={e.id}>
						<span className="date">
							{new Date(e.date).getDate() +
								"." +
								(new Date(e.date).getMonth() + 1) +
								"."}
						</span>
						{e.type === "message" &&
							"Nachricht von " +
								e.item.author +
								": " +
								e.item.text}
						{e.type === "note" &&
							"Kommentar über " + e.item.to + ": " + e.item.note}
						{e.type === "nomination" &&
							"Vorschlag für " +
								e.item.poll +
								": " +
								e.item.nomination}
						{e.type === "poll" && "Neue Umfrage: " + e.item.poll}
					</li>
				))
			)}
			{list.length > 10 && (
				<button
					className="list__expand"
					onClick={(e) => {
						setExpanded(!expanded);
					}}>
					{expanded ? (
						<>
							Weniger anzeigen
							<ExpandLessRoundedIcon />
						</>
					) : (
						<>
							Mehr anzeigen
							<ExpandMoreRoundedIcon />
						</>
					)}
				</button>
			)}
		</ul>
	);
};

export default ActivityList;
