import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import Cards from "../layout/Cards";

const Team = ({ team }) => {
	const user = useContext(UserContext).user;

	if (team.length > 0) {
		return (
			<ul className="grid">
				{team.map((member) => {
					return (
						<li key={member.user_id}>
							<img
								src={
									getAPI() + "public/images/" + member.picture
								}
								alt=""
								height="32px"
								width="32px"
								className="round"
							/>
							<p>{member.firstname + " " + member.lastname}</p>
						</li>
					);
				})}
			</ul>
		);
	} else {
		return (
			<span>
				Dieses Team hat noch keine Mitglieder. Hilf mit und trag dich
				ein!
			</span>
		);
	}
};

export default Team;
