import axios from "axios";
import { getAPI } from "../data/config";

const url = getAPI();

async function get(path, params = {}) {
	return await axios
		.get(url + path, {
			withCredentials: true,
			params: params,
		})
		.then((res) => {
			return res;
		})
		.catch(() => {
			return null;
		});
}

async function post(path, params = {}, config = {}) {
	return await axios
		.post(url + path, params, config)
		.then((res) => {
			return res.data;
		})
		.catch(() => {
			return false;
		});
}

async function remove(path, params = {}) {
	return await axios
		.delete(url + path, { data: params })
		.then((res) => {
			return res.status === 200 && res.data.affectedRows > 0;
		})
		.catch(() => {
			return false;
		});
}

async function getMessages() {
	return (await get("chat")).data;
}

async function postMessage(message) {
	return await post("chat", { message });
}

async function deleteMessage(messageID) {
	return await remove("chat/" + messageID);
}

async function getNotesUsers() {
	return (await get("notes")).data;
}

async function getNotes(userID) {
	return (await get("notes/" + userID)).data;
}

async function postNote(receiver, message) {
	return await post("notes", { receiver, message });
}

async function deleteNote(noteID) {
	return await remove("notes/" + noteID);
}

async function getWritersUsers() {
	return (await get("friendtexts")).data;
}

async function getWriters(userID) {
	return (await get("friendtexts/" + userID)).data;
}

async function postJoinWriters(userID) {
	return await post("friendtexts/join/" + userID);
}

async function postLeaveWriters(userID) {
	return await post("friendtexts/leave/" + userID);
}

async function postFriendtextReady(userID, ready) {
	return await post("friendtexts/ready/" + userID, { ready });
}

async function getProfile() {
	return (await get("profiles")).data;
}

async function getProfiles() {
	return (await get("profiles/all")).data;
}

async function updateProfile(profile) {
	return await post("profiles/update", { answers: profile });
}

async function getProfileCategories() {
	return (await get("profiles/categories")).data;
}

async function postProfileCategory(category, type) {
	return await post("profiles/categories", {
		category,
		type,
	});
}

async function deleteProfileCategory(categoryID) {
	return await remove("profiles/categories/" + categoryID);
}

async function getNhie() {
	return (await get("nhie")).data;
}

async function setNhie(questionID, answer) {
	return await post("nhie", { questionID, answer });
}

async function postNhieQuestion(question) {
	return await post("nhie/new", { question });
}

async function acceptNhieQuestion(questionID) {
	return await post("nhie/accept/" + questionID);
}

async function deleteNhieQuestion(questionID) {
	return await remove("nhie/" + questionID);
}

async function getPolls() {
	return (await get("polls")).data;
}

async function getPollsByGroup(group) {
	return (await get("polls/group/" + group)).data;
}

async function getPoll(pollID) {
	return (await get("polls/" + pollID)).data;
}

async function getPollByRole(role) {
	return (await get("polls/role/" + role)).data;
}

async function postPoll(title, group) {
	return await post("polls", { title, group });
}

async function acceptPollSuggestion(pollID) {
	return await post("polls/accept/" + pollID);
}

async function deletePoll(pollID) {
	return await remove("polls/" + pollID);
}

async function voteNomination(nominationID, vote) {
	return await post("nominations/" + nominationID + "/vote", { vote });
}

async function postNomination(pollID, nomination) {
	return await post("nominations/" + pollID, { nomination });
}

async function deleteNomination(nominationID) {
	return await remove("nominations/" + nominationID);
}

async function postComment(nominationID, comment) {
	return await post("comments/" + nominationID, { comment });
}

async function deleteComment(commentID) {
	return await remove("comments/" + commentID);
}

async function getTeams() {
	return (await get("teams")).data;
}

async function postTeam(name) {
	return await post("teams", { name });
}

async function postJoinTeam(teamID) {
	return await post("teams/join/" + teamID);
}

async function postLeaveTeam(teamID) {
	return await post("teams/leave/" + teamID);
}

async function deleteTeam(teamID) {
	return await remove("teams/" + teamID);
}

async function getUser(userID) {
	return (await get("users/" + userID)).data;
}

async function getUsers() {
	return (await get("users")).data;
}

async function acceptUser(userID) {
	return await post("users/accept/" + userID);
}

async function deleteUser(userID) {
	return await remove("users/" + userID);
}

async function updateUser(newFirstname, newLastname, newEmail) {
	return await post("users/update", {
		firstname: newFirstname,
		lastname: newLastname,
		email: newEmail,
	});
}

async function updatePassword(oldPassword, newPassword) {
	return await post("users/update/password", {
		oldPassword,
		newPassword,
	});
}

async function updateRole(userID, role) {
	return await post("users/update/role", { userID, role });
}

async function postUser(firstname, lastname, email, password, token) {
	return await post("register", {
		firstname,
		lastname,
		email,
		password,
		token,
	});
}

async function postLogin(email, password) {
	return await post("login", { email, password });
}

async function postProfilePicture(image, onUploadProgress) {
	const formData = new FormData();
	formData.append("image", image);

	return await post("users/upload", formData, { onUploadProgress });
}

async function postRequestPasswordReset(email) {
	return await post("resetPassword", { email });
}

async function postPasswordReset(userID, token, password) {
	return await post("resetPassword/set", { userID, token, password });
}

async function getMe() {
	return (await get("users/me")).data;
}

async function getFinancing() {
	return (await get("financing")).data;
}

async function postFinancing(type, amount, name) {
	return await post("financing", { type, amount, name });
}

async function deleteFinancing(financingID) {
	return await remove("financing/" + financingID);
}

async function getDashboard() {
	return (await get("dashboard")).data;
}

async function getNotificationCategories(subscription) {
	return await post("notifications/categories", { subscription });
}

async function postNotificationPreference(
	subscription,
	categoryID,
	preference
) {
	return await post("notifications/preference", {
		subscription,
		categoryID,
		preference,
	});
}

const api = {
	getMessages,
	postMessage,
	deleteMessage,

	getNotes,
	getNotesUsers,
	postNote,
	deleteNote,

	getWriters,
	getWritersUsers,
	postJoinWriters,
	postLeaveWriters,
	postFriendtextReady,

	getProfile,
	getProfiles,
	updateProfile,
	getProfileCategories,
	postProfileCategory,
	deleteProfileCategory,

	getNhie,
	setNhie,
	postNhieQuestion,
	acceptNhieQuestion,
	deleteNhieQuestion,

	getPolls,
	getPollsByGroup,
	getPoll,
	getPollByRole,
	postPoll,
	acceptPollSuggestion,
	deletePoll,
	voteNomination,
	postNomination,
	deleteNomination,
	postComment,
	deleteComment,

	getTeams,
	postTeam,
	postJoinTeam,
	postLeaveTeam,
	deleteTeam,

	getUser,
	getUsers,
	acceptUser,
	deleteUser,
	postProfilePicture,
	updateUser,
	updatePassword,
	updateRole,
	postUser,
	postLogin,
	postRequestPasswordReset,
	postPasswordReset,
	getMe,

	getFinancing,
	postFinancing,
	deleteFinancing,

	getDashboard,

	getNotificationCategories,
	postNotificationPreference,
};
export default api;
