import React, { useLayoutEffect } from "react";

const PageTitle = ({ title }) => {
	useLayoutEffect(() => {
		document.title = title + " – abiPlaner";

		return () => {
			document.title = "abiPlaner";
		};
	}, []);

	return <h2 className="title_buttons__title">{title}</h2>;
};

export default PageTitle;
