import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import { useState } from "react";
import api from "../../functions/api";
import {
	checkPasswordRepeat,
	checkPasswordRepeatReason,
} from "../../functions/passwordStrength";

const EinstellungenProfilPasswort = () => {
	const { user, setUser } = useContext(UserContext);

	const [oldPw, setOldPw] = useState("user.firstname");
	const [newPw, setNewPw] = useState("user.lastname");
	const [newPwConf, setNewPwConf] = useState("user.email");

	const [errorMessage, setErrorMessage] = useState("");

	return (
		<>
			<h2 className="settings__profile__password">Passwort ändern</h2>
			<div className="settings__profile">
				<div className="settings__profile__left">
					<img
						src={getAPI() + "public/images/" + user.picture}
						alt="Profilbild"
						className="settings__profile__picture round"
						height="128px"
						width="128px"
					/>
				</div>

				<div className="settings__profile__right">
					<form
						action=""
						className="settings__profile__right__password"
					>
						<label htmlFor="oldPassword">Altes Password: </label>
						<input
							type="password"
							id="oldPassword"
							className="textfeld"
							autoComplete="current-password"
							value={oldPw}
							onChange={(e) => {
								setOldPw(e.target.value);
							}}
						/>
						<label htmlFor="newPassword">Neues Passwort: </label>
						<input
							type="password"
							id="newPassword"
							className="textfeld"
							autoComplete="new-password"
							value={newPw}
							onChange={(e) => {
								setNewPw(e.target.value);
							}}
						/>
						<label htmlFor="repeatNewPassword">
							Neues Passwort wiederholen:{" "}
						</label>
						<input
							type="password"
							id="repeatNewPassword"
							className="textfeld"
							autoComplete="new-password"
							value={newPwConf}
							onChange={(e) => {
								setNewPwConf(e.target.value);
							}}
						/>

						<p className="error">{errorMessage}</p>

						<button
							type="submit"
							onClick={(e) => {
								e.preventDefault();

								if (checkPasswordRepeat(newPw, newPwConf)) {
									api.updatePassword(oldPw, newPw).then(
										(res) => {
											if (res.success) {
												alert(
													"Passwort erfolgreich geändert."
												);
											} else {
												setErrorMessage(
													"Fehler beim Ändern des Passworts."
												);
											}
										}
									);
								} else {
									setErrorMessage(
										checkPasswordRepeatReason(
											newPw,
											newPwConf
										)
									);
								}
							}}
						>
							Speichern
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default EinstellungenProfilPasswort;
