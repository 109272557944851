import React from "react";
import { NavLink } from "react-router-dom";
import PageTitle from "./PageTitle";
import logo from "../../assets/logo.svg";

const Page = ({ title, navigation, children, buttons = [] }) => {
	return (
		<>
			<section
				className={
					navigation ? "page-header page-header--nav" : "page-header"
				}>
				<div className="page-header__title-bar">
					<PageTitle title={title} />
					{buttons.map((button) => {
						return (
							<button
								key={button.title}
								className="page-header__button"
								onClick={button.onClick}>
								{button.icon} {button.title}
							</button>
						);
					})}
				</div>
				{navigation && (
					<nav className="sec-nav">
						<ul>
							{navigation.map((nav) => (
								<li key={nav.to}>
									<NavLink to={nav.to}>{nav.title}</NavLink>
								</li>
							))}
						</ul>
					</nav>
				)}
			</section>
			<section
				className={
					navigation
						? "page-content page-content--nav"
						: "page-content"
				}>
				<div className="loading-screen" aria-hidden>
					<img src={logo} alt="Inhalt wird geladen..." />
				</div>
				{children}
			</section>
		</>
	);
};

export default Page;
