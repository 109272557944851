import React from "react";

const ProgressBar = ({ progress }) => {
	if (progress > 100) {
		progress = 100;
	} else if (progress < 0 || isNaN(progress)) {
		progress = 0;
	}

	return (
		<div className="progress-bar">
			<div
				className="progress-bar__progress"
				style={{ width: progress + "%" }}>
				<p>{Math.round(progress)} %</p>
			</div>
		</div>
	);
};

export default ProgressBar;
