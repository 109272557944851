import axios from "axios";
import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import Popup from "../layout/Popup";

const EditAbstimmungPopup = ({ close, abstimmung }) => {
	const token = useContext(UserContext).user.token;

	const startDatum =
		abstimmung.ende !== null ? abstimmung.ende.substring(0, 10) : null;
	const [ende, setEnde] = useState(startDatum);

	function editAbstimmung() {
		axios
			.post(getAPI() + "actions/edit.php", {
				id: token,
				action: "abstimmung",
				aid: abstimmung.abstimmung_id,
				ende: ende,
			})
			.then(() => {
				close();
			});
	}

	return (
		<Popup close={() => close()} className="einstellungen__benutzer__popup">
			<h3>Ende bearbeiten</h3>

			<form
				onSubmit={(e) => {
					e.preventDefault();
					editAbstimmung();
				}}>
				<input
					type={"date"}
					name="Enddatum"
					id="enddatum"
					placeholder="Ende"
					value={ende}
					onChange={(e) => {
						setEnde(e.target.value);
					}}
				/>

				<div className="buttons">
					<button type="submit" tabIndex={0}>
						Speichern
					</button>
				</div>
			</form>
		</Popup>
	);
};

export default EditAbstimmungPopup;
