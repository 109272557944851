import React, { useContext } from "react";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import { useState } from "react";
import api from "../../functions/api";

const EinstellungenProfilEdit = () => {
	const { user, setUser } = useContext(UserContext);

	const [firstname, setFirstname] = useState(user.firstname);
	const [lastname, setLastname] = useState(user.lastname);
	const [email, setEmail] = useState(user.email);

	const [uploadProgress, setUploadProgress] = useState(0);

	async function onUploadProgress(e) {
		setUploadProgress(e.progress);
	}

	// TODO Error occurs when uploading an image, clicking on Upload again and then cancling the file selection

	async function setImage(image) {
		api.postProfilePicture(image, onUploadProgress).then((res) => {
			console.log(res);
			setUser({ ...user, picture: res.url });
			setUploadProgress(0);
		});
	}

	return (
		<div className="settings__profile">
			<div className="settings__profile__left">
				<img
					src={getAPI() + "public/images/" + user.picture}
					alt="Profilbild"
					className="settings__profile__picture round"
					height="128px"
					width="128px"
				/>

				<form
					className="upload-form"
					onSubmit={(e) => {
						e.preventDefault();
					}}>
					<label htmlFor="image" className="upload-label clickable">
						<UploadRoundedIcon />
						<span>Bild hochladen</span>
						<div
							className="upload-progress"
							style={{ width: uploadProgress * 100 + "%" }}></div>
					</label>

					<input
						className="upload-input"
						id="image"
						type="file"
						accept="image/jpeg, image/png"
						onChange={(e) => {
							setImage(e.target.files[0]);
						}}
					/>
				</form>
			</div>

			<div className="settings__profile__right">
				<form action="">
					<label htmlFor="firstname">Vorname: </label>
					<input
						type="text"
						id="firstname"
						className="textfeld"
						value={firstname}
						onChange={(e) => {
							setFirstname(e.target.value);
						}}
					/>
					<label htmlFor="lastname">Nachname: </label>
					<input
						type="text"
						id="lastname"
						className="textfeld"
						value={lastname}
						onChange={(e) => {
							setLastname(e.target.value);
						}}
					/>
					<label htmlFor="email">E-Mail: </label>
					<input
						type="email"
						id="email"
						className="textfeld"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>
					<button
						type="submit"
						onClick={(e) => {
							e.preventDefault();
							api.updateUser(firstname, lastname, email).then(
								() => {
									setUser({
										...user,
										firstname,
										lastname,
										email,
									});
								}
							);
						}}>
						Speichern
					</button>
				</form>
			</div>
		</div>
	);
};

export default EinstellungenProfilEdit;
