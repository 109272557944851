import React, { useContext } from "react";
import UserContext from "../context/UserContext";

const WarteAufBestaetigung = () => {
	const { setUser } = useContext(UserContext);

	function logout() {
		setUser();
	}

	return (
		<div className="beyond">
			<div className="beyond__box">
				Bitte warte, bis dein Account von einem Administrator oder
				Verwalter bestätigt wird.
				<button
					className="beyond__box__button"
					onClick={() => logout()}>
					Logout
				</button>
			</div>
			<footer>
				<a href="/datenschutz">Datenschutzerklärung</a>
			</footer>
		</div>
	);
};

export default WarteAufBestaetigung;
