import React from "react";
import { Outlet } from "react-router-dom";
import Page from "../components/layout/Page";

const Zeitung = () => {
	const navigation = [
		{ to: "schuelerrankings", title: "Schülerrankings" },
		{ to: "lehrerrankings", title: "Lehrerrankings" },
		{ to: "kommentare", title: "Kommentare" },
		{ to: "freundestexte", title: "Freundestexte" },
		{ to: "steckbrief", title: "Steckbrief" },
		{ to: "ichhabnochnie", title: "Ich hab noch nie" },
	];

	return (
		<Page title="Abi-Zeitung" navigation={navigation}>
			<Outlet />
		</Page>
	);
};

export default Zeitung;
