import React, { useContext, useEffect, useState } from "react";
import Team from "../components/teams/Team";
import UserContext from "../context/UserContext";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PersonRemoveRoundedIcon from "@mui/icons-material/PersonRemoveRounded";
import MoreMenu from "../components/layout/MoreMenu";
import api from "../functions/api";
import { useNavigate } from "react-router-dom";
import Page from "../components/layout/Page";

const Teams = () => {
	const user = useContext(UserContext).user;
	const navigate = useNavigate();

	const [teams, setTeams] = useState([]);
	const buttons = [];

	if (user.role >= 2) {
		buttons.push({
			title: "Team erstellen",
			icon: <GroupAddRoundedIcon />,
			onClick: () => {
				navigate("/teams/neu");
			},
		});
	}

	async function getTeams() {
		api.getTeams().then((res) => {
			setTeams(res);
		});
	}

	async function deleteTeam(teamID) {
		api.deleteTeam(teamID).then(() => {
			getTeams();
		});
	}

	async function addToTeam(teamID) {
		api.postJoinTeam(teamID).then(() => {
			getTeams();
		});
	}

	async function removeFromTeam(teamID) {
		api.postLeaveTeam(teamID).then(() => {
			getTeams();
		});
	}

	useEffect(() => {
		getTeams();
	}, []);

	return (
		<Page title="Teams" buttons={buttons}>
			<div className="teams">
				{teams.map((team) => {
					const teamMoreMenu = [];
					if (user.role >= 2) {
						teamMoreMenu.push(
							/*{ title: "Bearbeiten" }, */
							{
								title: "Löschen",
								action: () => {
									if (
										window.confirm(
											"Möchtest du das Team " +
												team.name +
												" wirklich löschen?"
										)
									)
										deleteTeam(team.team_id);
								},
							}
						);
					}

					return (
						<div className="team" key={team.team_id}>
							<div className="title_buttons">
								<h3>{team.name}</h3>

								<div className="title_buttons__buttons">
									{team.members.some((m) => {
										return m.user_id === user.userID;
									}) ? (
										<button
											className="team__item team__button"
											onClick={() => {
												removeFromTeam(team.team_id);
											}}
										>
											<PersonRemoveRoundedIcon />
											Verlassen
										</button>
									) : (
										<button
											className="team__item team__button"
											onClick={() => {
												addToTeam(team.team_id);
											}}
										>
											<PersonAddAltRoundedIcon />
											Mitmachen
										</button>
									)}

									<MoreMenu items={teamMoreMenu} />
								</div>
							</div>
							<Team team={team.members} />
						</div>
					);
				})}
			</div>
		</Page>
	);
};

export default Teams;
