import React from 'react'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

const Fehler = () => {
    return (
        <div className="fehler">
            <ErrorRoundedIcon />
            Es ist ein Fehler aufgetreten.
        </div>
    )
}

export default Fehler