import React, { useState } from "react";

import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

const MoreMenu = ({ items }) => {
	const [visible, setVisible] = useState(false);

	if (items.length > 0) {
		return (
			<div className="more_menu">
				<button
					type="button"
					title="Mehr"
					className="more_menu__button"
					onClick={() => {
						setVisible(!visible);
					}}>
					<MoreVertRoundedIcon />
				</button>
				<div
					className={
						"more_menu__popup " +
						(visible && "more_menu__popup--visible")
					}>
					<ul>
						{items.map((i) => {
							return (
								<li key={i.title}>
									<button
										type="button"
										className="button-full-width"
										title={i.title}
										onClick={i.action}>
										{i.title}
									</button>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	}
};

export default MoreMenu;
