import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getAPI } from "../../data/config";
import api from "../../functions/api";

const ZeitungBotschaften = () => {
	const [users, setUsers] = useState([]);

	function getUsers() {
		api.getNotesUsers().then((res) => {
			setUsers(res);
		});
	}

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<div className="zeitung__botschaften zeitung__botschaften--users">
			<div className="zeitung__description">
				<p>
					Kommentare sind kurze, anonyme Botschaften, die du deinen
					Mitschülerinnen und Mitschülern hinterlassen kannst. Sie
					werden in der Abi-Zeitung veröffentlicht.
				</p>
				<p>
					Schreib doch ein paar Kommentare zu deinen Freundinnen und
					Freunden, deinem Crush oder wem auch immer du sonst noch
					etwas mitteilen wolltest!
				</p>
				<p>
					Wenn du ein Problem mit einem Kommentar hast, der über dich
					geschrieben wurde, kannst du diesen selbstverständlich
					löschen.
				</p>
			</div>
			<ul className="grid">
				{users.map((u) => {
					return (
						<li key={u.user_id}>
							<Link to={u.user_id + "/"} className="card">
								<img
									src={
										getAPI() +
										"public/images/" +
										(u.picture || "")
									}
									alt={"Profilbild von " + u.firstname}
									height="48px"
									width="48px"
									className="round"
								/>
								<div>
									<h4>{u.firstname + " " + u.lastname}</h4>
									<p>
										{(u.note_count || "Keine ") +
											" " +
											(u.note_count === 1
												? "Kommentar"
												: "Kommentare")}
									</p>
								</div>
							</Link>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default ZeitungBotschaften;
