import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import api from "../../functions/api";

const ZeitungFreundestexteUser = () => {
	const user = useContext(UserContext).user;

	const { userid } = useParams();
	const [writers, setWriters] = useState([]);
	const [wUser, setWUser] = useState({});
	const [ready, setReady] = useState(false);

	function getUser() {
		api.getUser(userid).then((res) => {
			setWUser(res);
		});
	}

	function getWriters() {
		api.getWriters(userid).then((res) => {
			setWriters(res.writers);
			setReady(res.ready);
		});
	}

	function joinWriters() {
		api.postJoinWriters(userid).then(() => {
			getWriters();
		});
	}

	function leaveWriters() {
		api.postLeaveWriters(userid).then(() => {
			getWriters();
		});
	}

	useEffect(() => {
		getUser();
		getWriters();
	}, []);

	return (
		<div className="zeitung__botschaften zeitung__botschaften--user">
			<div className="zeitung__botschaften--user__user">
				<img
					src={
						wUser.picture === undefined
							? getAPI() + "public/images/standard.jpg"
							: getAPI() + "public/images/" + wUser.picture
					}
					alt="Profilbild"
					height="64px"
					width="64px"
					className="round"
				/>

				<div>
					<h2>{wUser.firstname + " " + wUser.lastname}</h2>
					<p>
						{writers.length +
							" " +
							(writers.length === 1 ? "Autor*in" : "Autor*innen")}
					</p>
				</div>
			</div>
			<div className="zeitung__botschaften--user__botschaften">
				<div className="zeitung__freundestexte__buttons">
					{user.userID !== Number(userid) &&
						(writers.some(
							(writer) => writer.user_id === user.userID
						) ? (
							<button
								className="zeitung__freundestexte__button"
								onClick={() => {
									leaveWriters();
								}}>
								Verlassen
							</button>
						) : (
							<button
								className="zeitung__freundestexte__button zeitung__freundestexte__button--mitmachen"
								onClick={() => {
									joinWriters();
								}}>
								Mitmachen
							</button>
						))}
					{user.role >= 2 && (
						<button
							onClick={(e) => {
								e.preventDefault();
								api.postFriendtextReady(
									Number(userid),
									!ready
								).then(() => {
									setReady(!ready);
								});
							}}>
							{ready ? (
								<CloseRoundedIcon titleAccess="Text hochgeladen" />
							) : (
								<CheckRoundedIcon titleAccess="Text nicht hochgeladen" />
							)}
						</button>
					)}
				</div>

				<h3>{writers.length === 1 ? "Autor*in" : "Autor*innen"}</h3>
				<ul className="list">
					{writers.length === 0 ? (
						<p>
							{user.userID !== Number(userid) ? (
								<>
									Es hat sich noch niemand zum Schreiben
									dieses Textes zugeordnet. Mach du doch den
									Anfang!
								</>
							) : (
								<>
									Es hat sich noch niemand zum Schreiben
									deines Textes zugeordnet. Frag doch deine
									Freund*innen, ob sie einen Text über dich
									schreiben wollen!
								</>
							)}
						</p>
					) : (
						<>
							{writers.map((b) => {
								return (
									<li key={b.user_id}>
										<img
											src={
												getAPI() +
												"public/images/" +
												b.picture
											}
											alt="Profilbild"
											className="round"
											width="32px"
											height="32px"
										/>
										{b.firstname + " " + b.lastname}
									</li>
								);
							})}
						</>
					)}
				</ul>
			</div>
		</div>
	);
};

export default ZeitungFreundestexteUser;
